import { useNavigate } from "react-router-dom";
import { HEADERS, API_URL } from "../config/api";

//Get match result label (P for Win, N for Draw, I for Lost, ? for not played)
export function getMatchResultLabel(result) {
  switch (result) {
    case "W":
      return "P";
    case "D":
      return "N";
    case "L":
      return "I";
    default:
      return "?";
  }
}

//Get string date in format e.g. 29.04.2024.
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed
  const year = date.getFullYear();

  return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}.`;
}

// // Navigation
export const navigateTo = (navigate, url) => {
  navigate(url);
};

export const useNavigation = () => {
  const navigate = useNavigate();
  return (url) => navigateTo(navigate, url);
};

export const formatStatValue = (value) => {
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    return "0"; // or "NaN" if you prefer to display that explicitly
  }
  if (floatValue === 0.0) {
    return "0";
  }
  return floatValue % 1 === 0 ? floatValue.toFixed(0) : floatValue.toFixed(2);
};

export const getCountries = async () => {
  try {
    const response = await fetch(`${API_URL}countries`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch countries");
      return null;
    }
  } catch (error) {
    console.error("Error fetching countries", error);
    return null;
  }
};
