import HeadingTitle from "../layout/HeadingTitle";
import React from "react";
import NoDataMessage from "../../shared/NoDataMessage";
import { Link } from "react-router-dom";
import { formatDate } from "../../services/shared-service";
const TeamList = ({ teamPlayers }) => {
  const allPlayersEmpty = teamPlayers.data.every(
    (team) => team.players.length === 0
  );
  if (
    !teamPlayers ||
    !teamPlayers.data ||
    teamPlayers.data.length === 0 ||
    allPlayersEmpty
  ) {
    return (
      <NoDataMessage message="Trenutno nema dostupnih podataka o igračima." />
    );
  }

  const players = teamPlayers.data[0].players;

  // Logging players
  players.forEach((player) => {});
  // Group players by type
  const groupedPlayers = teamPlayers.data.reduce((acc, playerType) => {
    if (playerType.players.length > 0) {
      acc[playerType.title] = playerType.players;
    }
    return acc;
  }, {});

  const translateFoot = (foot) => {
    switch (foot) {
      case "right":
        return "Desna";
      case "left":
        return "Lijeva";
      default:
        return foot;
    }
  };

  return (
    <div>
      {Object.entries(groupedPlayers).map(([type, players], index) => (
        <div key={index}>
          <div className="py-1">
            <HeadingTitle title={type} />
          </div>

          {players.map((player, idx) => (
            <Link
              key={idx}
              // to={`/player/${player.player_id}`} // Navigate to player profile page
            >
              <React.Fragment key={idx}>
                {idx !== 0 && <hr className="bg-divider border-1" />}

                <div className="flex items-center pl-2 relative p-1">
                  <div>
                    <img
                      src="/img/svg/playerIcon.svg"
                      alt="Player icon"
                      className="object-cover rounded-full h-[26px] w-[26px] "
                    />
                  </div>
                  <div className="ml-2 flex-grow">
                    <p className="text-lineupsText">{player.player_name}</p>
                    <div className="flex">
                      {player.foot && (
                        <div className="flex items-center text-xs text-textGrey mr-2">
                          <div>
                            <img
                              src="/img/svg/soccerboot.svg"
                              alt="Player icon"
                              className="object-cover rounded-full pr-1 h-[12px] w-[12px] "
                            />
                          </div>
                          <span>{translateFoot(player.foot)}</span>
                        </div>
                      )}
                      {player.birthdate && (
                        <div className="flex items-center text-xs text-textGrey">
                          <div>
                            <img
                              src="/img/svg/datecalender.svg"
                              alt="Player icon"
                              className="object-cover rounded-full h-[12px] w-[12px] "
                            />
                          </div>
                          <span>{formatDate(player.birthdate)}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {player.shirtnumber && (
                    <div className="relative ml-auto">
                      <div className="pr-3">
                        <img
                          src={
                            player.position === "Goalkeeper"
                              ? "/img/svg/homeGoalKit.svg"
                              : "/img/svg/kitHome.svg"
                          }
                          alt={
                            player.position === "Goalkeeper"
                              ? "Home goalkeeper kit"
                              : "Home kit"
                          }
                          className="object-cover h-[22px] w-[22px] z-10"
                        />
                        <div className="absolute top-3 left-[33%] transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center font-light text-white text-xs">
                          {player.shirtnumber}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TeamList;
