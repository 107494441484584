import { API_URL, HEADERS } from "../config/api";
import { formatStatValue } from "./shared-service";

export const getTeamPlayers = async (teamId) => {
  try {
    const response = await fetch(`${API_URL}players?teamid=${teamId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch team platers:", teamId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching team  players:", teamId, error);
    return [];
  }
};

export const getTeamAvgSeasonStats = async (teamId, seasonId) => {
  try {
    const response = await fetch(`${API_URL}team-season-stats?teamid=${teamId}&seasonid=${seasonId}&avg=true`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      const formattedData = {
        scoredGoals: formatStatValue(data.scoredGoals),
        expectedGoals: formatStatValue(data.expectedGoals),
        concededGoals: formatStatValue(data.concededGoals),
        expectedConcededGoals: formatStatValue(data.expectedConcededGoals),
        totalShoots: formatStatValue(data.totalShoots),
        shootsOnTarget: formatStatValue(data.shootsOnTarget),
        cornerKicks: formatStatValue(data.cornerKicks),
        yellowCards: formatStatValue(data.yellowCards),
        redCards: formatStatValue(data.redCards),
      };
      return formattedData;
    } else {
      console.error("Failed to fetch team average stats:", teamId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching team average stats", teamId, error);
    return [];
  }
};

export const getTeamTotalSeasonStats = async (teamId, seasonId) => {
  try {
    const response = await fetch(`${API_URL}team-season-stats?teamid=${teamId}&seasonid=${seasonId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      const formattedData = {
        scoredGoals: formatStatValue(data.scoredGoals),
        expectedGoals: formatStatValue(data.expectedGoals),
        concededGoals: formatStatValue(data.concededGoals),
        expectedConcededGoals: formatStatValue(data.expectedConcededGoals),
        totalShoots: formatStatValue(data.totalShoots),
        shootsOnTarget: formatStatValue(data.shootsOnTarget),
        cornerKicks: formatStatValue(data.cornerKicks),
        yellowCards: formatStatValue(data.yellowCards),
        redCards: formatStatValue(data.redCards),
      };
      return formattedData;
    } else {
      console.error("Failed to fetch team average stats:", teamId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching team average stats", teamId, error);
    return [];
  }
};
