import HeadingTitle from '../layout/HeadingTitle';

const InjuredSuspendedContent = ({
  injuredSuspendedHome,
  injuredSuspendedGuest,
}) => {
  const filterPlayersByDate = (players) => {
    const today = new Date();
    return players.filter((player) => {
      const endDate = player.endDate || player.expectedEndDate;
      return !endDate || new Date(endDate) >= today;
    });
  };

  const getIcon = (type) => {
    switch (type) {
      case 'INJURY':
        return '/img/svg/injury.svg';
      case 'SUSPENSION':
        return '/img/svg/yellowCard.svg';
      default:
        return null; // Fallback icon
    }
  };

  const filteredInjuredSuspendedHome =
    filterPlayersByDate(injuredSuspendedHome);
  const filteredInjuredSuspendedGuest = filterPlayersByDate(
    injuredSuspendedGuest
  );
  return (
    <div>
      {filteredInjuredSuspendedHome.length > 0 ||
      filteredInjuredSuspendedGuest.length > 0 ? (
        <div className="">
          <HeadingTitle title="Ozljeđeni i kažnjeni igrači" />
          <div className="flex">
            <div className="flex-1">
              <table className="w-full border border-bg-divider">
                <tbody>
                  {filteredInjuredSuspendedHome.map((player, index) => {
                    return (
                      <tr key={index}>
                        <td className="border border-gray-300 relative h-12">
                          <div className="flex items-center pl-5 p-1 relative">
                            <div className="relative">
                              <img
                                src="/img/svg/kitHome.svg"
                                alt="Home kit"
                                className="object-cover h-[22px] w-[24px] z-10"
                              />
                            </div>
                            <div className="ml-2 flex ">
                              <p className="text-lineupsText ">
                                {player?.name}
                              </p>
                              {player.type && (
                                <img
                                  src={getIcon(player.type)}
                                  alt={player.type}
                                  className="ml-1 h-[16px] w-[16px]"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex-1">
              <table className="w-full border border-bg-divider">
                <tbody>
                  {filteredInjuredSuspendedGuest.map((player, index) => {
                    return (
                      <tr key={index}>
                        <td className="border border-gray-300 relative h-12">
                          <div className="flex items-center pl-5 p-1 relative">
                            <div className="relative">
                              <img
                                src="/img/svg/kitAway.svg"
                                alt="Away kit"
                                className="object-cover h-[22px] w-[24px] z-10"
                              />
                            </div>
                            <div className="ml-2 flex ">
                              <p className="text-lineupsText">{player?.name}</p>
                              {player.type && (
                                <img
                                  src={getIcon(player.type)}
                                  alt={player.type}
                                  className="ml-1 h-[16px] w-[16px]"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InjuredSuspendedContent;
