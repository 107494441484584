//API_URL
//DEV ENV: https://api.dev.hrnogomet.hr/
//PROD ENV: https://api.prod.hrnogomet.hr/
export const API_URL = "https://api.prod.hrnogomet.hr/";

//API HEADERS
export const HEADERS = {
  accept: "application/json",
  "x-hrnogomet-uuid": "8147731f-bf23-4f6e-9484-75b9b55c9a9a",
};

//IFRAME_URL
//DEV ENV: https://widgets.dev.hrnogomet.hr/standings?league=1HNL
//PROD ENV: https://widgets.hrnogomet.hr/standings?league=1HNL
export const IFRAME_URL = "https://widgets.hrnogomet.hr/";
