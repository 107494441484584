import React from "react";
import { Link } from "react-router-dom";
import { formatDate, useNavigation } from "../services/shared-service";

function GameCard({ homeTeam, guestTeam, date, time, matchId, shortname }) {
  const formattedDate = formatDate(date);
  const navigateTo = useNavigation();

  return (
    <div>
      {homeTeam && guestTeam && date && time && (
        <div className="container flex mt-3 justify-around items-center px-10 overflow-auto">
          {/* Left side */}
          <div className="flex flex-col items-center">
            <div
              className="cursor-pointer"
              onClick={() =>
                navigateTo(`/team/${homeTeam.id}?matchId=${matchId}`)
              }
            >
              <img
                src={homeTeam.logoUrl}
                alt={homeTeam.name}
                className="object-cover rounded-full h-[24px] w-[24px]"
              />
            </div>
            <p className="font-medium text-black text-lineupsText mt-1">
              {homeTeam.name}
            </p>
          </div>

          {/* Middle */}
          <Link
            to={`/competition/${shortname}`} // Navigate to player profile page
            className="flex items-center"
          >
            <div className="flex flex-col items-center">
              <p className="font-normal text-xs">Sljedeća utakmica</p>
              <p className="font-bold text-black text-base">{time}h</p>
              <p className="font-medium text-lineupsText text-textSecondary">
                {formattedDate}
              </p>
            </div>
          </Link>
          {/* Right side */}
          <div className="flex flex-col items-center">
            <div
              className="cursor-pointer"
              onClick={() =>
                navigateTo(`/team/${guestTeam.id}?matchId=${matchId}`)
              }
            >
              <img
                src={guestTeam.logoUrl}
                alt={guestTeam.name}
                className="object-cover rounded-full h-[24px] w-[24px]"
              />
            </div>
            <p className="font-medium text-black text-lineupsText mt-1">
              {guestTeam.name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default GameCard;
