import React from "react";
import { useNavigation } from "../services/shared-service";
import NoDataMessage from "../shared/NoDataMessage";
import { TeamFlagBadge } from "./TeamFlagBadge";

function TeamGameCard({ team }) {
  const today = new Date();
  const navigateTo = useNavigation();

  if (!team || !team.matches) {
    return <NoDataMessage message="No team data available" />;
  }

  // Filter future matches to find the next upcoming match
  const nextMatch = team.matches.find((match) => {
    const matchDate = new Date(match.date);
    return (
      matchDate >= today ||
      (matchDate.toDateString() === today.toDateString() && match.played === 0)
    );
  });

  // If there's no upcoming match, return null or display a message
  if (!nextMatch) {
    return null;
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("hr-HR", { day: "numeric", month: "long" });
  };
  const formattedDate = formatDate(nextMatch.date);

  return (
    <div className="container flex mt-3 justify-around items-center px-10 overflow-auto">
      <TeamFlagBadge
        flagUrl={nextMatch.homeTeam.logoUrl}
        onClick={() => navigateTo(`/team/${nextMatch.homeTeam.id}`)}
        teamName={nextMatch.homeTeam.name}
      />

      <div className="flex flex-col items-center">
        <p className="font-normal text-xs">Sljedeća utakmica</p>
        <p className="font-bold text-black text-base">{nextMatch.time}h</p>
        <p className="font-medium text-lineupsText text-textSecondary">
          {formattedDate}
        </p>
      </div>

      <TeamFlagBadge
        flagUrl={nextMatch.guestTeam.logoUrl}
        onClick={() => navigateTo(`/team/${nextMatch.guestTeam.id}`)}
        teamName={nextMatch.guestTeam.name}
      />
    </div>
  );
}

export default TeamGameCard;
