import React from "react";

const ExpandButton = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="w-full h-auto px-3 py-2 border border-[#DFE0E4] bg-transparent text-center cursor-pointer transition duration-300 ease-in-out hover:bg-[#f0f0f0]"
    >
      {children}
    </button>
  );
};

export default ExpandButton;
