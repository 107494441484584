import React, { useState, useEffect, useCallback } from "react";
import { fetchLeagues, fetchLeagueMatches, fetchLeagueScorers } from "../services/competition-service";
import { useParams } from "react-router-dom";
import Loader from "../shared/Loader";
import CompetitionMatches from "../shared/CompetitionMatches";
import CompetitionPlayerStatistics from "../components/competition/CompetitionPlayerStatistics";
import WebHeadingTitle from "./webShared/WebHeadingTitle";
import CompetitionStatistics from "../components/competition/CompetitionStatistics";
import MarketStats from "../components/competition/MarketCompetition";
import MatchInfo from "../components/competition/MatchInfo";
import TeamGameCard from "../shared/TeamGameCard";
import { TeamStandingsTable } from "../shared/TeamStandingsTable";
import ExpandButton from "./webShared/ExpandButton";

const CompetitionWeb = () => {
  const [leagueDetails, setLeagueDetails] = useState(null);
  const [leagueMatches, setLeagueMatches] = useState(null);
  const [pastMatches, setPastMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentRound, setCurrentRound] = useState(1);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [leagueScorers, setLeagueScorers] = useState(null);
  const [expandedUpcoming, setExpandedUpcoming] = useState(false);
  const [expandedPast, setExpandedPast] = useState(false);

  const { shortname } = useParams();

  const fetchLeague = useCallback(async () => {
    try {
      const data = await fetchLeagues();
      let foundLeague = null;

      data.forEach((county) => {
        county.leagues.forEach((league) => {
          if (league.shortname === shortname) {
            foundLeague = league;
          }
        });
      });

      if (foundLeague) {
        fetchMatches(foundLeague.id);
        fetchScorers(foundLeague.id);
        setLeagueDetails(foundLeague);
      }
    } catch (err) {
      console.error("Error fetching leagues:", err);
    } finally {
      setLoading(false);
    }
  }, [shortname]);

  useEffect(() => {
    fetchLeague();
  }, [fetchLeague]);

  const fetchMatches = async (leagueId) => {
    try {
      const matchesData = await fetchLeagueMatches(leagueId);
      setLeagueMatches(matchesData || []);
    } catch (err) {
      console.error("Error fetching matches:", err);
    }
  };

  const fetchScorers = async (leagueId) => {
    try {
      const data = await fetchLeagueScorers(leagueId);
      setLeagueScorers(data);
    } catch (error) {
      console.error("Error fetching scorers:", error);
    }
  };

  useEffect(() => {
    if (!leagueMatches || !leagueMatches.matches) return;

    const today = new Date();

    // Filter matches for the current round and matches that are not finished yet
    const currentRoundMatches = leagueMatches.matches.filter((match) => {
      const matchDate = new Date(match.date);
      return parseInt(match.round) === currentRound && matchDate >= today;
    });

    // If there are no matches left for the current round, move to the next round
    if (currentRoundMatches.length === 0 && leagueMatches.matches.some((match) => parseInt(match.round) > currentRound)) {
      setCurrentRound((prevRound) => prevRound + 1);
    }
  }, [leagueMatches, currentRound]);

  const currentRoundMatches = upcomingMatches.filter((match) => parseInt(match.round) === currentRound);

  useEffect(() => {
    if (!leagueMatches || !leagueMatches.matches) return;

    const today = new Date();

    const upcoming = leagueMatches.matches.filter((match) => new Date(match.date) >= today);
    const past = leagueMatches.matches.filter((match) => new Date(match.date) < today);

    setUpcomingMatches(upcoming);
    setPastMatches(past);
  }, [leagueMatches]);

  const handleToggleHeight = (component) => {
    if (component === "upcoming") {
      setExpandedUpcoming(!expandedUpcoming);
      
    } else if (component === "past") {
      setExpandedPast(!expandedPast);
      
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container mx-auto flex-grow">
            <WebHeadingTitle message="Info" />
            <div className="flex flex-wrap">
              {/* Part 1: Game Card and Match Info */}
              <div className="flex-1 pr-2 flex flex-col">
                <div>
                  <TeamGameCard team={leagueMatches} />
                </div>
                <div className="flex flex-wrap mx-1 overflow-x-auto">
                  {currentRoundMatches.map((match, index) => (
                    <MatchInfo
                      key={index}
                      homeTeam={match.homeTeam.name}
                      guestTeam={match.guestTeam.name}
                      homeLogoUrl={match.homeTeam.logoUrl}
                      guestLogoUrl={match.guestTeam.logoUrl}
                      time={match.time}
                      date={new Date(match.date).toLocaleDateString("hr-HR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    />
                  ))}
                </div>
              </div>
              {/* Part 2: Market Stats */}
              <div className="flex-1 p-2">
                <MarketStats />
              </div>
              {/* Part 3: Competition Statistics */}
              <div className="flex-1 pl-2 pt-2">
                <CompetitionStatistics />
              </div>
            </div>
          </div>
          {leagueDetails && (
            <div className="container mx-auto">
              <WebHeadingTitle message="Tablica" />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <TeamStandingsTable leagueShortname={leagueDetails.shortname} leagueName={leagueDetails.subLeagueName} homeTeam="" guestTeam="" />
                {leagueScorers && (
                  <>
                    <div className="container mx-auto">
                      <WebHeadingTitle message="Statistika" />
                      <div className="py-2">
                        <CompetitionPlayerStatistics leagueScorers={leagueScorers} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {leagueMatches && (
            <>
              <div className="container mx-auto">
                <WebHeadingTitle message="Utakmice" />
                <div className="flex w-2/3 py-2 ">
                  {upcomingMatches.length > 0 && (
                    <div className={`flex-1 pr-4 ${pastMatches.length === 0 ? "w-full" : ""}`}>
                      <div className={`${expandedUpcoming ? "h-auto" : "h-1/5 overflow-hidden"}`}>
                        <CompetitionMatches leagueMatches={{ matches: upcomingMatches }} />
                      </div>
                      <ExpandButton onClick={() => handleToggleHeight("upcoming")}>{expandedUpcoming ? "Učitaj manje" : "Učitaj više"}</ExpandButton>
                    </div>
                  )}
                  {pastMatches.length > 0 && (
                    <div className={`flex-1 ${upcomingMatches.length === 0 ? "w-full" : ""}`}>
                      <div className={`${expandedPast ? "h-full" : "h-1/5 overflow-hidden"}`}>
                        <CompetitionMatches leagueMatches={{ matches: pastMatches }} />
                      </div>
                      <ExpandButton onClick={() => handleToggleHeight("past")}>{expandedPast ? "Učitaj manje" : "Učitaj više"}</ExpandButton>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CompetitionWeb;
