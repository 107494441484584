import { useState, useMemo, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import HeadingTitle from "../layout/HeadingTitle";
import { formatDate } from "../../services/shared-service";
const PlayerProfile = ({ playerDetails, teamList }) => {

  const { playerId } = useParams(); // Retrieve playerId from URL params
  const [selectedPlayer, setSelectedPlayer] = useState(playerId);

  const teamPlayers = useMemo(() => {
    if (teamList && teamList.data && Array.isArray(teamList.data)) {
      return teamList.data.flatMap((group) => group.players);
    }
    return [];
  }, [teamList]);

  const playerRefs = useRef([]);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (playerId === selectedPlayer) {
      setSelectedPlayer(playerId); // Update selectedPlayer if playerId changes
    }
  }, [playerId, selectedPlayer]);

  useEffect(() => {
    if (playerId && scrollContainerRef.current) {
      const playerElement = scrollContainerRef.current.querySelector(`#player-${playerId}`);
      if (playerElement) {
        playerElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [playerId]);

  useEffect(() => {
    if (selectedPlayer !== null && scrollContainerRef.current) {
      const selectedPlayerIndex = teamPlayers.findIndex((player) => player.player_id === selectedPlayer);

      if (selectedPlayerIndex !== -1 && playerRefs.current[selectedPlayerIndex]) {
        const playerElement = playerRefs.current[selectedPlayerIndex];
        const container = scrollContainerRef.current;

        const containerRect = container.getBoundingClientRect();
        const playerRect = playerElement.getBoundingClientRect();

        const containerScrollLeft = container.scrollLeft;
        const containerWidth = containerRect.width;

        const playerLeft = playerRect.left - containerRect.left + containerScrollLeft;
        const playerWidth = playerRect.width;

        const scrollLeft = playerLeft + containerWidth / 2 + playerWidth / 2;

        container.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    }
  }, [selectedPlayer, teamPlayers]);

  const handlePlayerClick = (player) => {
    setSelectedPlayer(Number(player.player_id)); // Ensure consistent data type
  };

  const stats = [
    { type: "Goals", value: 5 },
    { type: "Assists", value: 6 },
    { type: "Minutes Played", value: 800 },
    { type: "Games Started", value: 8 },
  ];

  const positionTranslations = {
    Goalkeeper: {
      translation: "Vratar",
      abbreviation: "GK",
      top: "10%",
      left: "44%",
    },
    Defender: {
      translation: "Obrambeni",
      abbreviation: "DF",
      top: "43%",
      left: "44%",
    },
    Midfielder: {
      translation: "Vezni",
      abbreviation: "MF",
      top: "33%",
      left: "44%",
    },
    Attacker: {
      translation: "Napadač",
      abbreviation: "AT",
      top: "24%",
      left: "43%",
    },
    Striker: {
      translation: "Napadač",
      abbreviation: "ST",
      top: "24%",
      left: "43%",
    },
    // Add other positions and their translations/abbreviations here
  };

  const position = playerDetails?.position || "Unknown";
  const {
    translation: translatedPosition,
    abbreviation: positionAbbreviation,
    top: topPercentage,
    left: leftPercentage,
  } = positionTranslations[position] || {
    translation: position,
    abbreviation: position.slice(0, 2).toUpperCase(),
    top: "50%",
    left: "50%",
  };

  const PlayerItem = ({ title, info }) => (
    <div className="flex flex-col  flex-1 mx-4 p-2">
      <p className="text-center font-fw700 text-text16 mt-1">{title}</p>
      <p className="text-center text-scoreText text-textSecondary">{info}</p>
    </div>
  );
  const translateFoot = (foot) => {
    switch (foot) {
      case "right":
        return "Desna";
      case "left":
        return "Lijeva";
      default:
        return foot;
    }
  };

  const calculateAge = (birthdate) => {
    if (!birthdate) return "N/A";
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return `${age}.god`; // Append ".god" for the format you requested
  };

  const playerInfo = [
    { title: calculateAge(playerDetails?.birthdate) || "N/A", info: formatDate(playerDetails?.birthdate)},
    { title: translateFoot(playerDetails?.foot), info: "Preferirana noga" },
    { title: playerDetails?.shirtnumber || "N/A", info: "Broj dresa" },
  ];
  const playerInfo2 = [
    
    { title: playerDetails?.height, info: "Visina" },
    { title: playerDetails?.weight, info: "Težina" },
  ];
  

  let statsTitle = "Statistika";
  let positionStats = stats; // Default to the general stats

  switch (playerDetails?.position) {
    case "Goalkeeper":
      statsTitle = "Statistika vratara";
      positionStats = [
        { type: "Clean Sheets", value: 3 },
        { type: "Saves", value: 45 },
        { type: "Penalties Saved", value: 1 },
        { type: "Goals Conceded", value: 8 },
      ];
      break;
    case "Defender":
      statsTitle = "Statistika obrambenog igrača";
      positionStats = [
        { type: "Tackles", value: 35 },
        { type: "Interceptions", value: 20 },
        { type: "Clearances", value: 50 },
        { type: "Blocked Shots", value: 10 },
      ];
      break;
    case "Midfielder":
      statsTitle = "Statistika veznog igrača";
      positionStats = [
        { type: "Passes Completed", value: 500 },
        { type: "Key Passes", value: 15 },
        { type: "Duels Won", value: 30 },
        { type: "Crosses", value: 20 },
      ];
      break;
    case "Attacker":
    case "Striker": // Include Striker position as well
      statsTitle = "Statistika napadača";
      positionStats = [
        { type: "Shots on Target", value: 25 },
        { type: "Goals", value: 12 },
        { type: "Assists", value: 6 },
        { type: "Chances Created", value: 18 },
      ];
      break;
    default:
      // For unknown positions, default to general stats
      break;
  }

  return (
    <div>
      {/* SCROLLABLE TEAM LIST  */}

      <div className="px-1 pb-1" ref={scrollContainerRef}>
        <div className="player-list flex overflow-x-auto space-x-1 hide-scrollbar">
          {teamPlayers.map((player, index) => (
            <div
              key={index}
              id={`player-${player.player_id}`} // Add id attribute with unique player ID
              ref={(ref) => (playerRefs.current[index] = ref)}
              className={`player-card flex items-center rounded-sm cursor-pointer min-w-max h-[39px] ${Number(selectedPlayer) === Number(player.player_id) ? "bg-white" : "bg-containerPast"}`}
              onClick={() => handlePlayerClick(player)}
            >
              <Link
                // to={`/player/${player.player_id}`} // Navigate to player profile page
                className="flex items-center"
              >
                <img
                  src={player.imgUrl || "/img/svg/playerIcon.svg"} // Use player icon if imgUrl is not provided
                  alt={player.name}
                  className="w-5 h-5 rounded-full m-1 flex-shrink-0"
                />
                <span className="pr-2 text-scoreText font-fw500 whitespace-nowrap flex-shrink-0">{player.player_name}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* PLAYER BASIC INFO */}
      <div className="bg-containerPast p-2">
        <table>
          <tbody>
            <tr>
              <td>
                <img src="/img/svg/playerIcon.svg" alt="playerIcon" className="object-cover rounded-full h-[50px] w-[50px] ml-2" />
              </td>
              <td>
                <div className="p-1">
                  <p className="font-fw700 text-text18 p-1">{playerDetails?.player_name || "Player Name"}</p>
                  <div className="flex items-center">
                    <img src={playerDetails?.imagePath || "/img/svg/cro.svg"} alt="countryFlag" className="object-cover h-[25px] w-[25px] mx-1 rounded-full" />
                    <span className="font-fw500 text-text14 text-textSecondary">{playerDetails?.imageUrl || "Country"}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* PLAYER INFO -DETAILED */}
      <div className="grid grid-cols-3 gap-4 mb-2">
        {playerInfo.map((stat, index) => (
          <PlayerItem key={index} title={stat.title} info={stat.info} />
        ))}
      </div>

      {/* PLAYER INFO - DETAILED */}
      <div className="grid grid-cols-3 gap-4 mb-4">
        {playerInfo2.map((stat, index) => (
          <PlayerItem key={index} title={stat.title} info={stat.info} />
        ))}
      </div>


      <HeadingTitle title={statsTitle} />
      <div className="">
        {positionStats.map((stat, index) => (
          <table key={index} className="w-full">
            <tbody>
              <tr>
                <td className="text-start p-2 font-fw500 text-scoreText">{stat.type}</td>
                <td className="text-end pr-3 font-fw700 text-scoreText">{stat.value}</td>
              </tr>
            </tbody>
          </table>
        ))}
      </div>
      <HeadingTitle title={"Pozicija na terenu"} />
      <div className="p-2">
        <div className="flex items-center justify-between p-2">
          <div className="flex flex-col items-start mr-4">
            <p className="font-fw700 text-text16 text-left">{translatedPosition}</p>
            <p className="text-scoreText text-textSecondary text-left">Primarna pozicija</p>
          </div>
          <div className="relative pr-5">
            <img src="/img/svg/pitch.svg" alt="pitch" className="object-cover w-[136px] h-[211px]" />
            <div
              className="absolute bg-bgMain text-white text-scoreText px-1 rounded"
              style={{
                top: topPercentage,
                left: leftPercentage,
                transform: "translate(-50%, -50%)",
              }}
            >
              {positionAbbreviation}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlayerProfile;
