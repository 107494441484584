import React, { useState, useEffect } from "react";
import TeamList from "../components/team/TeamList";
import TeamMatches from "../shared/TeamMatches";
import TeamGameCard from "../shared/TeamGameCard";
import TeamDetailsLastFiveMatches from "../shared/TeamDetailsLastFiveMatches";
import TeamStatistic from "../components/team/TeamStatistics";
import { useParams } from "react-router-dom";
import { getTeamMatches, getTeamLastFive, fetchMatchDetails } from "../services/match-service";
import { getTeamPlayers } from "../services/team-service";
import Loader from "../shared/Loader";
import WebHeadingTitle from "./webShared/WebHeadingTitle";
import { TeamStandingsTable } from "../shared/TeamStandingsTable";

const TeamWeb = () => {
  const [teamMatches, setTeamMatches] = useState(null);
  const [teamLastFive, setTeamLastFive] = useState(null);
  const [teamPlayerList, setTeamPlayerList] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { teamId } = useParams();
  // const location = useLocation();

  // Function to get query parameter from location object
  // const getQueryParameter = useCallback(
  //   (name) => {
  //     const params = new URLSearchParams(location.search);
  //     return params.get(name);
  //   },
  //   [location]
  // );

  //   const matchId = useMemo(
  //     () => getQueryParameter("matchId"),
  //     [getQueryParameter]
  //   );
  const matchId = 125056;
  useEffect(() => {
    if (teamId) {
      fetchTeamData(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    if (matchId) {
      fetchMatchData(matchId);
    }
  }, [matchId]);

  const fetchTeamData = async (teamId) => {
    try {
      setLoading(true);
      const [matches, lastFive, players] = await Promise.all([getTeamMatches(teamId), getTeamLastFive(teamId), getTeamPlayers(teamId)]);
      setTeamMatches(matches || []);
      setTeamLastFive(lastFive || []);
      setTeamPlayerList(players || []);
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchMatchData = async (matchId) => {
    try {
      const data = await fetchMatchDetails(matchId);
      setMatchDetails(data || {});
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const match = matchDetails?.matches[0];
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container mx-auto">
            <WebHeadingTitle message="Detalji" />
            <div className="flex justify-between">
              <div className="flex-1 p-2">
                <TeamGameCard team={teamMatches} />
                {/* <BetButtons></BetButtons> */}
                {matchDetails &&
                  matchDetails.matches.length > 0 && ( // Conditionally render TeamStandingsTable
                    <TeamStandingsTable leagueShortname={match.league.shortname} leagueName={match.league.name} homeTeam={match.homeTeam} guestTeam={match.guestTeam} />
                  )}
              </div>
              <div className="flex-1 p-2">
                <TeamDetailsLastFiveMatches lastFiveMatches={teamLastFive} teamId={teamId} />
              </div>
              <div className="flex-1 p-2">
                <TeamStatistic matchDetails={matchDetails} />
              </div>
            </div>
          </div>
          <WebHeadingTitle message="Utakmice" />
          <TeamMatches teamMatches={teamMatches} selectedTeamId={teamId} />
          <WebHeadingTitle message="Momčad" />
          <TeamList teamPlayers={teamPlayerList} teamId={teamId} />
        </>
      )}
    </div>
  );
};

export default TeamWeb;
