import React from "react";

const TabButton = ({label, isActive, onClick}) => {
    return (
        <button
            className={`${
                isActive ? "bg-bgMain text-white" : "#F4F7F8 text-gray-800"
            } hover: hover:text-white font-medium py-2 px-4 mx-1 focus:outline-none rounded-sm uppercase flex-grow text-xs sm:text-sm md:text-base`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default TabButton;
