import React, { useCallback, useEffect, useMemo, useState } from "react";
import TeamDetails from "../shared/TeamDetails";
import TeamList from "../components/team/TeamList";
import TeamMatches from "../shared/TeamMatches";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchMatchDetails,
  getTeamLastFive,
  getTeamMatches,
} from "../services/match-service";
import { getTeamPlayers } from "../services/team-service";
import Loader from "../shared/Loader";
import { Tabs } from "../shared/Tabs";

const TeamPage = () => {
  const [activeTab, setActiveTab] = useState("details");
  const [teamMatches, setTeamMatches] = useState(null);
  const [teamLastFive, setTeamLastFive] = useState(null);
  const [teamPlayerList, setTeamPlayerList] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { teamId } = useParams();
  const location = useLocation();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Function to get query parameter from location object
  const getQueryParameter = useCallback(
    (name) => {
      const params = new URLSearchParams(location.search);
      return params.get(name);
    },
    [location],
  );

  const matchId = useMemo(
    () => getQueryParameter("matchId"),
    [getQueryParameter],
  );

  useEffect(() => {
    if (teamId) {
      fetchTeamData(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    if (matchId) {
      fetchMatchData(matchId);
    }
  }, [matchId]);

  const fetchTeamData = async (teamId) => {
    try {
      setLoading(true);
      const [matches, lastFive, players] = await Promise.all([
        getTeamMatches(teamId),
        getTeamLastFive(teamId),
        getTeamPlayers(teamId),
      ]);
      setTeamMatches(matches || []);
      setTeamLastFive(lastFive || []);
      setTeamPlayerList(players || []);
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMatchData = async (matchId) => {
    try {
      const data = await fetchMatchDetails(matchId);
      setMatchDetails(data || {});
    } catch (error) {
      console.error("Error fetching match data:", error);
    }
  };

  const tabs = [
    {
      label: "Detalji",
      isActive: activeTab === "details",
      onClick: () => handleTabClick("details"),
    },
    {
      label: "Utakmice",
      isActive: activeTab === "games",
      onClick: () => handleTabClick("games"),
    },
    {
      label: "Momčad",
      isActive: activeTab === "team",
      onClick: () => handleTabClick("team"),
    },
  ];

  return (
    <div>
      <div className="p-3 bg-white ">
        <Tabs tabs={tabs} />
      </div>
      {loading && <Loader />}
      {!loading && activeTab === "details" && (
        <TeamDetails
          teamDetails={teamMatches}
          lastFiveMatches={teamLastFive}
          selectedTeamId={teamId}
          matchDetails={matchDetails}
        />
      )}
      {!loading && activeTab === "games" && (
        <TeamMatches teamMatches={teamMatches} selectedTeamId={teamId} />
      )}
      {!loading && activeTab === "team" && (
        <TeamList teamPlayers={teamPlayerList} teamId={teamId} />
      )}
    </div>
  );
};

export default TeamPage;
