import { useEffect, useState } from "react";
import HeadingTitle from "../layout/HeadingTitle";
import { getTeamTotalSeasonStats } from "../../services/team-service";
import Loader from "../../shared/Loader";

const TeamStatistic = ({ matchDetails }) => {
  const [teamStats, setTeamStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      if (matchDetails?.matches?.length > 0) {
        const stats = await getTeamTotalSeasonStats(
          matchDetails.matches[0].homeTeam.id,
          matchDetails.matches[0].season_id
        );
        setTeamStats(stats || {});
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchStats();
  }, [matchDetails]);

  const statistics = [
    { type: "Zabijeni golovi", value: teamStats.scoredGoals },
    { type: "Očekivani golovi", value: teamStats.expectedGoals },
    { type: "Primljeni golovi", value: teamStats.concededGoals },
    {
      type: "Očekivani primljeni golovi",
      value: teamStats.expectedConcededGoals,
    },
    { type: "Udarci", value: teamStats.totalShoots },
    { type: "Udarci u okvir", value: teamStats.shootsOnTarget },
    { type: "Korneri", value: teamStats.cornerKicks },
    { type: "Žuti karton", value: teamStats.yellowCards },
    { type: "Crveni karton", value: teamStats.redCards },
  ];
  if (loading) {
    return <Loader />;
  }

  if (Object.keys(teamStats).length === 0) {
    return null;
  }

  return (
    <div>
      <HeadingTitle title={"Statistika"} />
      <div className="flex w-full p-1">
        <table className="w-full">
          <tbody>
            {statistics.map((stat, index) => {
              if (stat.type === "Kartoni") {
                return (
                  <tr key={index} className="flex w-full p-2 text-scoreText">
                    <td className="w-1/2 ">{stat.type}</td>
                    <td className="w-1/2 text-right font-fw700">
                      <img
                        src="/img/svg/yellowCard.svg"
                        alt="Yellow Card"
                        className="inline-block h-4 mx-1" // adjusted height class
                      />
                      {stat.yellowCards}
                      <img
                        src="/img/svg/redCard.svg"
                        alt="Red Card"
                        className="inline-block h-4 ml-1 mx-1 pl-1" // adjusted height class
                      />
                      {stat.redCards}
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr key={index} className="flex w-full p-2 text-scoreText">
                    <td className="w-1/2 ">{stat.type}</td>
                    <td className="w-1/2 text-right font-fw700">
                      {stat.value}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamStatistic;
