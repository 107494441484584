export const EmptyState = ({ infoText }) => {
  return (
    <div className="w-full flex items-center flex-wrap justify-center gap-2">
      <svg
        width="150"
        height="150"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 7H18V9H10V7Z" fill="#e5e5e5" />
        <path d="M18 11H10V13H18V11Z" fill="#e5e5e5" />
        <path d="M10 15H18V17H10V15Z" fill="#e5e5e5" />
        <path d="M8 7H6V9H8V7Z" fill="#e5e5e5" />
        <path d="M6 11H8V13H6V11Z" fill="#e5e5e5" />
        <path d="M8 15H6V17H8V15Z" fill="#e5e5e5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 21V3H2V21H22ZM4 5H20V19H4V5Z"
          fill="#e5e5e5"
        />
      </svg>
      <div className="grid gap-4 w-60">
        <div>
          <h2 className="text-center text-black text-xl font-semibold leading-loose pb-2">
            {infoText}
          </h2>
        </div>
      </div>
    </div>
  );
};
