import { useNavigation } from "../services/shared-service";
import HeadingTitle from "../components/layout/HeadingTitle";
import { TeamFlagBadge } from "./TeamFlagBadge";

function TeamDetailsLastFiveMatches({ lastFiveMatches, teamId }) {
  const matches = lastFiveMatches || [];
  const navigateTo = useNavigation();

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("hr-HR", { day: "numeric", month: "long" });
  };

  if (matches.length === 0) {
    return null;
  }

  const getResultLabel = (match) => {
    // Ensure all IDs are numbers for comparison !!!!!!!!!!!!
    const homeTeamId = Number(match.homeTeam.id);
    const guestTeamId = Number(match.guestTeam.id);
    const selectedId = Number(teamId);

    if (selectedId === homeTeamId) {
      if (match.homeTeam.scored > match.guestTeam.scored) {
        return "P"; // Home team wins
      } else if (match.homeTeam.scored < match.guestTeam.scored) {
        return "I"; // Home team loses
      } else {
        return "N"; // Draw
      }
    } else if (selectedId === guestTeamId) {
      if (match.guestTeam.scored > match.homeTeam.scored) {
        return "P"; // Guest team wins
      } else if (match.guestTeam.scored < match.homeTeam.scored) {
        return "I"; // Guest team loses
      } else {
        return "N"; // Draw
      }
    } else {
      return "N"; // If the selected team is neither the home nor guest team
    }
  };

  return (
    <div className="container mx-auto sm:px-6 md:px-8">
      <HeadingTitle title={"Posljednjih 5 utakmica"} />

      <div>
        {matches.map((match, index) => (
          <div className="border-b border-divider" key={index}>
            <div
              className="cursor-pointer"
              onClick={() => navigateTo(`/match/${match.id}`)}
            >
              <div className="flex items-center mt-1">
                <div className="w-full flex justify-between pl-3">
                  <div className="flex items-center">
                    <img
                      src="/img/svg/goal.svg"
                      alt="Competition Logo"
                      className="w-4 h-4 mr-2"
                    />
                    <p className="text-[#212629] font-fw700">
                      {match.league.name}
                    </p>
                  </div>
                  <p className="max-w-[400px] text-scoreText mt-1 pr-3 text-textSecondary">
                    {formatDate(match.date)}
                  </p>
                </div>
              </div>
              <div className="flex">
                <table className="flex-grow">
                  <tbody>
                    <tr className="">
                      <td className="">
                        <div className="flex pl-6 my-2">
                          <TeamFlagBadge flagUrl={match.homeTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.homeTeam.name}
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-end items-center">
                          <div
                            className={`pr-3 mt-1 ${
                              match.homeTeam.scored > match.guestTeam.scored
                                ? "text-black font-medium"
                                : "text-textSecondary"
                            }`}
                          >
                            {match.homeTeam.scored}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="">
                        <div className="flex pl-6 mb-2">
                          <TeamFlagBadge flagUrl={match.guestTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.guestTeam.name}
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-end items-center">
                          <div
                            className={`pr-3 mb-2 ${
                              match.guestTeam.scored > match.homeTeam.scored
                                ? "text-black font-medium"
                                : "text-textSecondary"
                            }`}
                          >
                            {match.guestTeam.scored}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="border-l border-divider my-2"></div>
                <div className="w-6 h-6 mx-3 items-center justify-center mt-7 text-white text-center">
                  <div
                    className={`rounded-lg ${
                      getResultLabel(match) === "P"
                        ? "bg-mainGreen"
                        : getResultLabel(match) === "N"
                        ? "bg-secondaryBgLight"
                        : "bg-redContainer"
                    }`}
                  >
                    {getResultLabel(match)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamDetailsLastFiveMatches;
