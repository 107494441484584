import React from "react";
import { useState, useEffect } from "react";
import Loader from "../shared/Loader";
import { API_URL, HEADERS } from "../config/api";
export default function Results() {
  const [isLoading, setIsLoading] = useState(true);
  const [leagueData, setLeagueData] = useState([]);
  const [countyData, setCountyData] = useState([]);
  // eslint-disable-next-line
  const [matchesData, setMatchesData] = useState([]);
  const [currentRound, setCurrentRound] = useState([]);
  const [matchesByRound, setMatchesByRound] = useState([]);
  const [roundNumbers, setRoundNumbers] = useState([]);

  useEffect(() => {
    const getLeagueFromQueryParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("league");
    };

    const getRoundFromQueryParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("round");
    };

    const fetchResults = async () => {
      const leagueParam = getLeagueFromQueryParam();
      const roundParam = getRoundFromQueryParam();
      if (leagueParam) {
        try {
          const countyResponse = await fetch(`${API_URL}county?assignedLeagues=true`, { headers: HEADERS });
          const countyData = await countyResponse.json();

          let found = false;
          let leagueId = 0;
          countyData.forEach((county) => {
            county["leagues"].forEach((league) => {
              if (league["shortname"] === leagueParam && !found) {
                setLeagueData(league);
                setCountyData(county);
                leagueId = league["id"];
                found = true;
              }
            });
          });

          const matchesResponse = await fetch(`${API_URL}matches?leagueId=${leagueId}`, { headers: HEADERS });
          const matchesData = await matchesResponse.json();

          setMatchesData(matchesData);

          const processMatchesData = () => {
            let matchesWithRounds = matchesData["matches"].reduce((acc, match) => {
              if (!acc[match.round]) {
                acc[match.round] = [];
              }
              acc[match.round].push(match);
              return acc;
            }, {});

            setMatchesByRound(matchesWithRounds);

            const roundNumbers = Object.keys(matchesWithRounds);
            setRoundNumbers(roundNumbers);
            if (roundParam) {
              const parsedRound = parseInt(roundParam);
              if (parsedRound >= 1 && parsedRound <= roundNumbers.length) {
                setCurrentRound(parsedRound);
              }
            } else {
              setCurrentRound(parseInt(roundNumbers[0]));
            }
          };

          if (matchesData["matches"]) {
            processMatchesData();
          }

          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchResults();
  }, []);

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiPhone = /iPhone/i.test(navigator.userAgent);

  const appLink = isAndroid ? "https://play.google.com/store/apps/details?id=com.hr.nogomet" : isiPhone ? "https://apps.apple.com/us/app/hrnogomet/id1533846210" : "https://hrnogomet.hr";

  // Handle pagination
  const handleRoundChange = (round) => {
    round = parseInt(round); // Convert round to integer

    if (round >= 1 && round <= roundNumbers.length) {
      setCurrentRound(round);
    }
  };

  return (
    <div className="container mx-auto my-8 px-4 sm:px-6 md:px-8">
      <div style={{ background: "#AF2418", textAlign: "left" }} className="flex items-center mb-6 rounded">
        <h1 className="text-sm text-white mb-2 px-2 py-2 mt-2">Pratite omiljene lige u aplikaciji HRnogomet</h1>
        <a href={appLink} target="_blank" rel="noopener noreferrer">
          <button className="border border-white text-white font-bold py-1 px-2 rounded ml-2" style={{ background: "transparent" }}>
            Preuzmi
          </button>
        </a>
      </div>
      {leagueData && countyData && (
        <div className="flex items-center mb-4">
          <img src={countyData.logoUrl} alt={leagueData.name} className="mr-2 w-6 h-6" />
          <h3 className="text-lg font-bold">{leagueData.name}</h3>
          <p className="ml-2">{countyData.name}</p>
        </div>
      )}
      <div className="bg-white shadow-md rounded my-6 overflow-x-auto">
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">{Loader()}</div>
        ) : (
          <div>
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="text-sm text-slate-500">
                  <th className="py-3 px-1 text-center w-6 hidden">Time</th>
                  <th className="py-3 px-2 text-left hidden">Match</th>
                  <th className="py-3 px-2 text-right hidden">Result</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {matchesByRound[currentRound].map((match, index) => {
                  const homeTeamWon = match.homeTeam.scored > match.guestTeam.scored;
                  const guestTeamWon = match.homeTeam.scored < match.guestTeam.scored;

                  const isCurrentlyPlaying = match.currently_playing === 1 && match.time_status_code === "Live";
                  const isHalfTime = match.currently_playing === 1 && match.time_status_code === "HT";
                  const isGamePlayed = match.played === 1;

                  return (
                    <tr key={match.id} className="border-b border-gray-200">
                      <td className="text-left px-2 py-2 w-[80px]">
                        <div className={`flex items-center mb-1 font-medium ${isCurrentlyPlaying || isHalfTime ? "text-red-500" : ""}`}>{isCurrentlyPlaying ? "UŽIVO" : isHalfTime ? "HT" : match.time}</div>
                      </td>
                      <td className="text-left">
                        <div className={`flex items-center mb-1 ${homeTeamWon ? "font-extrabold" : "font-medium"}`}>
                          <img src={match.homeTeam.logoUrl} alt={match.homeTeam.name} className="mr-2 w-4 h-4" />
                          {match.homeTeam.name}
                        </div>
                        <div className={`flex items-center mb-1 ${guestTeamWon ? "font-extrabold" : "font-medium"}`}>
                          <img src={match.guestTeam.logoUrl} alt={match.guestTeam.name} className="mr-2 w-4 h-4" />
                          {match.guestTeam.name}
                        </div>
                      </td>
                      {isGamePlayed ? (
                        <td className="text-right">
                          <div className={`flex items-center mb-1 ${homeTeamWon ? "font-extrabold" : guestTeamWon ? "font-medium" : "font-medium"}`}>{match.homeTeam.scored}</div>
                          <div className={`flex items-center ${guestTeamWon ? "font-extrabold" : homeTeamWon ? "font-medium" : "font-medium"}`}>{match.guestTeam.scored}</div>
                        </td>
                      ) : null}
                      {isCurrentlyPlaying ? (
                        <td className="text-right">
                          <div className={`flex items-center mb-1 font-medium text-red-600`}>{match.homeTeam.scored}</div>
                          <div className={`flex items-center font-medium text-red-600`}>{match.guestTeam.scored}</div>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="3" className="py-3 px-2 text-left font-light">
                    Podatke omogućio HRnogomet
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-center mt-4 mb-2">
              <button className="px-2 py-1 mx-2 rounded bg-[#AF2418] text-white" onClick={() => handleRoundChange(currentRound - 1)} disabled={currentRound === 1}>
                {"<<"}
              </button>
              <div className="flex items-center">
                <span className="font-bold">{currentRound}.</span>
                <span className="ml-1">kolo</span>
              </div>
              <button className="px-2 py-1 mx-2 rounded bg-[#AF2418] text-white" onClick={() => handleRoundChange(currentRound + 1)} disabled={currentRound === roundNumbers.length}>
                {">>"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
