import { API_URL, HEADERS } from "../config/api";

export const getPlayer = async (playerId) => {
  try {
    const response = await fetch(`${API_URL}players/${playerId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch player:", playerId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching player:", playerId, error);
    return [];
  }
};
