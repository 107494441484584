import React, { useState, useEffect } from "react";
import { TeamStandingsTable } from "../../shared/TeamStandingsTable";
import GameCard from "../../shared/GameCard";
import TeamLastFiveMatches from "../../shared/TeamLastFiveMatches";
import TeamLastFiveH2HMatches from "../../shared/TeamLastFiveH2HMatches";
import { fetchLeagues } from "../../services/competition-service";

export default function Head2Head({
  matchDetails,
  homeTeamLastFive,
  guestTeamLastFive,
  lastFiveH2HMatches,
}) {
  const [selectedTeam, setSelectedTeam] = useState("homeTeam");
  const [leagueDetails, setLeagueDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  useEffect(() => {
    if (matchDetails.matches && matchDetails.matches.length > 0) {
      const match = matchDetails.matches[0];
      const leagueShortname = match.league.shortname;

      const fetchLeague = async () => {
        try {
          const data = await fetchLeagues();
          let foundLeague = null;

          data.forEach((country) => {
            country.leagues.forEach((league) => {
              if (league.shortname === leagueShortname) {
                foundLeague = league;
              }
            });
          });

          if (foundLeague) {
            setLeagueDetails(foundLeague);
          }
          setIsLoading(false); // Set loading to false after fetching league details
        } catch (err) {
          console.error("Error fetching leagues:", err);
        }
      };

      fetchLeague();
    }
  }, [matchDetails]);

  if (!matchDetails.matches || matchDetails.matches.length === 0 || isLoading) {
    return null; // Return null if matches are not available or still loading
  }

  const match = matchDetails.matches[0];

  return (
    <div className="container mx-auto sm:px-6 md:px-8 overflow-auto">
      {leagueDetails &&
        leagueDetails.isCup === 0 && ( // Conditionally render the table
          <TeamStandingsTable
            leagueShortname={match.league.shortname}
            leagueName={match.league.name}
            homeTeam={match.homeTeam}
            guestTeam={match.guestTeam}
            matchId={match.id}
          />
        )}
      <GameCard
        homeTeam={match.homeTeam}
        guestTeam={match.guestTeam}
        date={match.date}
        time={match.time}
        matchId={match.id}
        shortname={match.league.shortname}
      />
      {/* <BetButtons /> */}

      <TeamLastFiveMatches
        homeTeam={match.homeTeam}
        guestTeam={match.guestTeam}
        homeTeamLastFive={homeTeamLastFive}
        guestTeamLastFive={guestTeamLastFive}
        selectedTeam={selectedTeam}
        onTeamSelect={handleTeamSelect}
      />
      <TeamLastFiveH2HMatches
        lastFiveH2HMatches={lastFiveH2HMatches}
        homeTeam={match.homeTeam}
        guestTeam={match.guestTeam}
        selectedTeam={selectedTeam}
        onTeamSelect={handleTeamSelect}
      />
    </div>
  );
}
