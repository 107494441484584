import React from "react";

function translateResult(result) {
  switch (result) {
    case "W":
      return "P";
    case "D":
      return "N";
    case "L":
      return "I";
    default:
      return "?";
  }
}

function StandingsForm({ standingsData }) {
  return (
    <table className="min-w-max w-full table-auto">
      <thead>
        <tr className="text-sm text-slate-500">
          <th className="py-3 px-1 text-center">#</th>
          <th className="py-3 px-2 text-left">Klub</th>
          <th className="py-3 px-2 text-center hidden">Forma</th>
        </tr>
      </thead>
      <tbody className="text-gray-600 text-sm font-light">
        {standingsData.map((team, index) => (
          <tr key={team["teamId"]} className="border-b border-gray-100">
            <td className="py-3 px-1 text-center font-medium">{index + 1}.</td>
            <td className="py-3 px-2 flex items-center justify-between font-medium">
              <div className="flex items-center">
                <img src={team["teamLogo"]} alt={team["teamName"]} className="mr-2 w-4 h-4" />
                {team["teamName"]}
              </div>
              <div className="flex">
                {standingsData[index]?.form?.slice(-5).map((result, index) => (
                  <div key={index} className={`rounded-full w-6 h-6 flex items-center justify-center mx-1 ${result === "W" ? "bg-green-500 text-white" : result === "L" ? "bg-red-500 text-white" : "bg-blue-500 text-white"}`}>
                    <span className="font-bold">{translateResult(result)}</span>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan="9" className="py-3 px-2 text-left font-light">
            Podatke omogućio HRnogomet
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default StandingsForm;
