import { useEffect, useRef, useState } from "react";

export const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabsContainerRef = useRef(null);

  // Setup style for indicator
  useEffect(() => {
    if (tabs.length === 0) return;
    const activeTabElement = tabsContainerRef.current.children[activeTab];
    setIndicatorStyle({
      width: activeTabElement.offsetWidth,
      transform: `translateX(${activeTabElement.offsetLeft - 8}px)`,
    });
  }, [activeTab, tabs.length]);

  if (tabs.labels === 0) return null;

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div
      className="w-full flex flex-row items-center justify-between p-2 rounded-lg overflow-hidden bg-bgLight text-text-primary relative"
      ref={tabsContainerRef}
    >
      {tabs.map(({ label, isActive, onClick }, index) => (
        <div
          className={`grid place-items-center rounded-sm z-20 text-xs uppercase font-medium cursor-pointer tap-highlight-transparent 
          ${isActive ? "animate-toActiveColor" : ""}`}
          style={{ width: `calc(100% / ${tabs.length})` }}
          onClick={() => {
            handleTabClick(index);
            onClick && onClick();
          }}
          key={index}
        >
          {label}
        </div>
      ))}
      <div
        className="bg-accent-primary rounded absolute left-2 right-2 bottom-0 h-1 z-10 transition-transform duration-250 ease-out bg-bgMain"
        style={indicatorStyle}
      ></div>
    </div>
  );
};
