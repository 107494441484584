export const Chip = ({ onClick, active, children }) => {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer uppercase text-xs
        ${active ? "bg-bgMain text-white border border-transparent" : "border border-bgMain text-bgMain"}
        transition-colors duration-200`}
    >
      {children}
    </button>
  );
};
