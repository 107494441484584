import React from "react";
import { useState, useEffect } from "react";
import Loader from "../shared/Loader";
import { API_URL, HEADERS } from "../config/api";
import StandingsOverall from "../components/standings/StandingsOverall";
import StandingsHome from "../components/standings/StandingsHome";
import StandingsAway from "../components/standings/StandingsAway";
import StandingsForm from "../components/standings/StandingsForm";

function Standings() {
  const [standingsData, setStandingsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [leagueData, setLeagueData] = useState([]);
  const [countyData, setCountyData] = useState([]);
  const [view, setView] = useState("overall");
  const [subLeagues, setSubLeagues] = useState([]);

  useEffect(() => {
    const getLeagueFromQueryParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("league");
    };

    const fetchStandingsData = async () => {
      const leagueParam = getLeagueFromQueryParam();
      if (leagueParam) {
        try {
          const response = await fetch(`${API_URL}team-standings?shortname=${leagueParam}`, { headers: HEADERS });
          const data = await response.json();

          // Check for subleagues
          if (data.subLeagues && data.subLeagues.length > 0) {
            setSubLeagues(data.subLeagues);
          } else if (data.teamStandings) {
            const sortedData = data.teamStandings.sort((a, b) => {
              if (a.pointsOverall !== b.pointsOverall) {
                return b.pointsOverall - a.pointsOverall;
              }
              return b.homeScored + b.awayScored - b.homeReceived - b.awayReceived - (a.homeScored + a.awayScored - a.homeReceived - a.awayReceived);
            });
            setStandingsData(sortedData);
          }

          const countyResponse = await fetch(`${API_URL}county?assignedLeagues=true`, { headers: HEADERS });
          const countyData = await countyResponse.json();

          let found = false;
          countyData.forEach((county) => {
            county.leagues.forEach((league) => {
              if (league.id === data.leagueId && !found) {
                setLeagueData(league);
                setCountyData(county);
                found = true;
              }
            });
          });

          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchStandingsData();
  }, [view]);

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiPhone = /iPhone/i.test(navigator.userAgent);

  const appLink = isAndroid ? "https://play.google.com/store/apps/details?id=com.hr.nogomet" : isiPhone ? "https://apps.apple.com/us/app/hrnogomet/id1533846210" : "https://hrnogomet.hr";

  return (
    <div className="container mx-auto my-8 px-4 sm:px-6 md:px-8">
      <div style={{ background: "#AF2418", textAlign: "left" }} className="flex items-center mb-6 rounded">
        <h1 className="text-sm text-white mb-2 px-2 py-2 mt-2">Pratite omiljene lige u aplikaciji HRnogomet</h1>
        <a href={appLink} target="_blank" rel="noopener noreferrer">
          <button className="border border-white text-white font-bold py-1 px-2 rounded ml-2" style={{ background: "transparent" }}>
            Preuzmi
          </button>
        </a>
      </div>
      <div className="flex justify-center mb-4">
        <button onClick={() => setView("overall")} className={`px-4 py-2 mx-1 rounded-full focus:outline-none ${view === "overall" ? "bg-[#AF2418] text-white" : "bg-white border border-[#AF2418] text-[#AF2418]"}`}>
          Sve
        </button>
        <button onClick={() => setView("home")} className={`px-4 py-2 mx-1 rounded-full focus:outline-none ${view === "home" ? "bg-[#AF2418] text-white" : "bg-white border border-[#AF2418] text-[#AF2418]"}`}>
          Doma
        </button>
        <button onClick={() => setView("away")} className={`px-4 py-2 mx-1 rounded-full focus:outline-none ${view === "away" ? "bg-[#AF2418] text-white" : "bg-white border border-[#AF2418] text-[#AF2418]"}`}>
          Gosti
        </button>
        <button onClick={() => setView("form")} className={`px-4 py-2 mx-1 rounded-full focus:outline-none ${view === "form" ? "bg-[#AF2418] text-white" : "bg-white border border-[#AF2418] text-[#AF2418]"}`}>
          Forma
        </button>
      </div>
      {leagueData && countyData && (
        <div className="flex items-center mb-4">
          <img src={countyData.logoUrl} alt={leagueData.name} className="mr-2 w-6 h-6" />
          <h3 className="text-lg font-bold">{leagueData.name}</h3>
          <p className="ml-2">{countyData.name}</p>
        </div>
      )}
      <div className="bg-white shadow-md rounded my-6 overflow-x-auto">
        {isLoading ? ( // Conditional rendering based on loading state
          <div className="flex justify-center items-center h-screen">{Loader()}</div>
        ) : (
          <>
            {subLeagues.length > 0 ? (
              subLeagues.map((subLeague) => (
                <div key={subLeague.subLeagueId}>
                  <h2 className="text-lg font-bold mb-2">{subLeague.subLeagueName}</h2>
                  {view === "overall" && <StandingsOverall standingsData={subLeague.teamStandings} />}
                  {view === "home" && <StandingsHome standingsData={subLeague.teamStandings} />}
                  {view === "away" && <StandingsAway standingsData={subLeague.teamStandings} />}
                  {view === "form" && <StandingsForm standingsData={subLeague.teamStandings} />}
                </div>
              ))
            ) : (
              <>
                {view === "overall" && <StandingsOverall standingsData={standingsData} />}
                {view === "home" && <StandingsHome standingsData={standingsData} />}
                {view === "away" && <StandingsAway standingsData={standingsData} />}
                {view === "form" && <StandingsForm standingsData={standingsData} />}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Standings;
