import TeamGameCard from "../../shared/TeamGameCard";
import MatchInfo from "./MatchInfo";
import CompetitionStatistics from "./CompetitionStatistics";
import MarketStats from "./MarketCompetition";
import { useEffect, useState } from "react";
import {
  fetchTopBettingMarkets,
  fetchSeasonStats,
} from "../../services/competition-service";

const InfoTab = ({ matches, shortname, seasonId, leagueName }) => {
  const [currentRound, setCurrentRound] = useState(1);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [topBettingMarkets, setTopBettingMarkets] = useState([]);
  const [competitionStatistics, setCompetitionStatistics] = useState([]);

  useEffect(() => {
    if (!matches || !matches.matches) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset to midnight to compare dates only

    // Filter matches for the current round and matches that have not yet started
    const currentRoundMatches = matches.matches.filter((match) => {
      const matchDate = new Date(match.date);
      matchDate.setHours(0, 0, 0, 0); // Reset to midnight to compare dates only
      return (
        parseInt(match.round) === currentRound &&
        matchDate >= today && // Include today's matches
        match.played !== 1 // Filter out matches that have already been played
      );
    });

    // If there are no matches left for the current round, move to the next round
    if (
      currentRoundMatches.length === 0 &&
      matches.matches.some((match) => parseInt(match.round) > currentRound)
    ) {
      setCurrentRound((prevRound) => prevRound + 1);
    } else {
      setUpcomingMatches(currentRoundMatches);
    }

    const fetchMarkets = async () => {
      const data = await fetchTopBettingMarkets(shortname);
      if (data) {
        setTopBettingMarkets(data);
      }
    };

    const fetchStats = async () => {
      const data = await fetchSeasonStats(seasonId);
      if (data) {
        setCompetitionStatistics(data);
      }
    };

    if (shortname) {
      (async () => await fetchMarkets())();
    }

    if (seasonId) {
      (async () => await fetchStats())();
    }
  }, [matches, currentRound, shortname, seasonId]);

  return (
    <div>
      <TeamGameCard team={{ matches: upcomingMatches }} />
      {/* <div className="w-full p-2">
        <button className="bg-buttonMainBlue w-full h-[40px] text-white uppercase text-lineupsText leading-4 rounded">
          Razrada utakmice
        </button>
      </div> */}
      {/* <div className="bg-bgLight text-center py-3 my-1">
        <p className="text-xs font-fw500">Tko će pobijediti?</p>
        <div className="flex justify-center mt-3">
          <button className="w-[90px] h-10 bg-paletteTertiaryLight text-textSecondaryLight text-text14 border-1 border-divider mx-2">
            1
          </button>
          <button className="w-[90px] h-10 bg-cardContainerGrey text-textSecondaryLight text-text14  border-2 border-divider mx-2">
            X
          </button>
          <button className="w-[90px] h-10 bg-paletteInfoLight text-textSecondaryLight text-text14  border-1 border-divider mx-2 ">
            2
          </button>
        </div>
      </div> */}
      <div className="flex mx-1 my-1 overflow-x-auto snap-x snap-mandatory">
        {upcomingMatches.map((match, index) => (
          <MatchInfo
            key={index}
            matchId={match.id}
            homeTeam={match.homeTeam.name}
            guestTeam={match.guestTeam.name}
            homeLogoUrl={match.homeTeam.logoUrl}
            guestLogoUrl={match.guestTeam.logoUrl}
            time={match.time}
            date={new Date(match.date).toLocaleDateString("hr-HR", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          />
        ))}
      </div>

      <MarketStats topBettingMarkets={topBettingMarkets} />
      <CompetitionStatistics
        competitionStatistics={competitionStatistics}
        leagueName={leagueName}
        matches={matches}
      />
    </div>
  );
};

export default InfoTab;
