import React from "react";

const EventTypePenaltyShootOut = ({
  minute,
  playerName,
  isHome,
  currentScore,
  text,
}) => {
  return (
    <div className="">
      {isHome === 1 ? (
        <React.Fragment>
          <div className="flex items-center justify-start h-10">
            <div className="flex items-center justify-start font-medium text-scoreText text-black mr-2">
              {minute}'
            </div>
            <div className="border border-grey-15 flex items-center mr-3 py-1">
              {text === "saved" && (
                <React.Fragment>
                  <img
                    src="/img/svg/missed_pen.svg"
                    alt="Penalty"
                    className="h-15 w-15 pl-1"
                  />
                  <span className="font-medium text-scoreText text-mainBlue px-1 mr-1">
                    {currentScore}
                  </span>
                </React.Fragment>
              )}
              {text === "scored" && (
                <img
                  src="/img/svg/penalty.svg"
                  alt="Penalty"
                  className="h-15 w-15 px-1"
                />
              )}
            </div>
              <div className="font-medium text-scoreText text-black">
                {playerName}
              </div>
            
          </div>
        </React.Fragment>
      ) : (
        <div className="flex items-center justify-end h-10">
          <React.Fragment>
            {(
              <div className="font-medium text-scoreText text-black">
                {playerName}
              </div>
            )}
            <div className="border border-grey-15 flex items-center ml-3 py-1">
              {text === "saved" && (
                <React.Fragment>
                  <img
                    src="/img/svg/missed_pen.svg"
                    alt="Penalty"
                    className="h-15 w-15 px-1"
                  />
                 
                </React.Fragment>
              )}
              {text === "scored" && (
                <img
                  src="/img/svg/penalty.svg"
                  alt="Penalty"
                  className="h-15 w-15"
                />
              )}
            </div>
            <div className="w-30 h-30 flex items-center justify-center font-medium text-scoreText text-black ml-2">
              {minute}'
            </div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};

export default EventTypePenaltyShootOut;
