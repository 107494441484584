import React from "react";

const NoFormationMessage = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <img
        src="/img/svg/noFormationImage.svg"
        alt="Pitch"
        className="w-full h-full px-1"
        style={{ display: "block" }}
      />
      <div
        style={{
          position: "absolute",
          top: "7%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className="p-3 text-center">
          <p className="font-fw700 text-lg">
            Moguće postave dostupne 3 dana pred utakmicu
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoFormationMessage;
