import React from "react";

const Lineups = ({
  homeTeamPlayers,
  guestTeamPlayers,
  homeFormation,
  guestFormation,
  isLoading,
  matchEvents,
}) => {
  function formatFormation(formation) {
    return formation.length >= 3 ? formation.split("").join(" ") : formation;
  }

  function extractSurname(fullName) {
    const nameParts = fullName.split(" ");
    return nameParts.length > 1 ? nameParts.pop() : fullName;
  }

  const EventIndicator = ({ playerEvent, index }) => {
    const getEventIcon = (eventType, offset) => {
      switch (eventType) {
        case 1:
          return (
            <img
              src="/img/svg/goal.svg"
              width={12}
              height={14}
              alt="Goal"
              className={`absolute top-0 w-[15px] h-[15px]`}
              style={{ right: `${8 * offset}px` }}
            />
          );
        case 2:
          return (
            <img
              src="/img/svg/yellowCard.svg"
              width={12}
              height={14}
              alt="Yellow Card"
              className="absolute top-0 left-8 w-[15px] h-[15px]"
            />
          );
        case 3:
          return (
            <img
              src="/img/svg/ownGoal.svg"
              width={12}
              height={14}
              alt="Own Goal"
              className="absolute top-0 right-8 w-[15px] h-[15px]"
            />
          );
        case 4:
          return (
            <img
              src="/img/svg/redCard.svg"
              width={12}
              height={14}
              alt="Red Card"
              className="absolute top-0 left-8 w-[15px] h-[15px]"
            />
          );
        case 6:
          return (
            <img
              src="/img/svg/goal.svg"
              width={12}
              height={14}
              alt="Penalty Goal"
              className="absolute top-0 right-8 w-[15px] h-[15px]"
            />
          );
        case 7:
          return (
            <img
              src="/img/svg/substitution.svg"
              width={12}
              height={14}
              alt="Sub out"
              className="absolute bottom-0 left-8 w-[15px] h-[15px]"
            />
          );
        case 8:
          return (
            <img
              src="/img/svg/secondYellow.svg"
              width={12}
              height={14}
              alt="Second Yellow"
              className="absolute top-0 left-8 w-[15px] h-[15px]"
            />
          );
        default:
          return null; // Return null for unknown types
      }
    };
    const offset = index + 4;
    const eventIcon = getEventIcon(playerEvent.type, offset);
    if (!eventIcon) {
      return null; // If there's no icon for the event type or it's skipped due to logic, return null
    }
    return <div>{eventIcon}</div>;
  };

  const playersPositions = {
    // Formation-> left depends on side(home or guest)
    442: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "15%", left: "10%" },
      6: { top: "15%", left: "33%" },
      5: { top: "15%", left: "57%" },
      2: { top: "15%", left: "81%" },
      11: { top: "27%", left: "10%" },
      8: { top: "27%", left: "33%" },
      4: { top: "27%", left: "57%" },
      7: { top: "27%", left: "81%" },
      9: { top: "40%", left: "33%" },
      10: { top: "40%", left: "57%" },
    },
    41212: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "13%", left: "10%" }, // Left Defender
      6: { top: "13%", left: "33%" }, // Center Left Defender
      5: { top: "13%", left: "57%" }, // Center Right Defender
      2: { top: "13%", left: "81%" }, // Right Defender
      4: { top: "22%", left: "45.5%" }, // Defensive Midfielder
      11: { top: "28%", left: "10%" }, // Left Midfielder
      7: { top: "28%", left: "81%" }, // Center Midfielder
      8: { top: "33%", left: "45.5%" }, // Center Midfielder
      9: { top: "42%", left: "30%" }, // Forward Left
      10: { top: "42%", left: "60%" }, // Forward Right
    },
    433: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "15%", left: "10%" },
      6: { top: "15%", left: "33%" },
      5: { top: "15%", left: "57%" },
      2: { top: "15%", left: "81%" },
      8: { top: "27%", left: "15%" },
      4: { top: "27%", left: "45.5%" },
      7: { top: "27%", left: "75%" },
      11: { top: "40%", left: "15%" },
      9: { top: "40%", left: "45.5%" },
      10: { top: "40%", left: "75%" },
    },
    451: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "15%", left: "10%" }, // Left Defender
      6: { top: "15%", left: "33%" }, // Center Left Defender
      5: { top: "15%", left: "57%" }, // Center Right Defender
      2: { top: "15%", left: "81%" }, // Right Defender
      11: { top: "33%", left: "5%" }, // Left Midfielder
      8: { top: "27%", left: "25%" }, // Center Left Midfielder
      10: { top: "27%", left: "45.5%" }, // Center Midfielder
      4: { top: "27%", left: "65%" }, // Center Right Midfielder
      7: { top: "33%", left: "85%" }, // Right Midfielder
      9: { top: "40%", left: "45.5%" }, // Forward
    },
    4411: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      11: { top: "24%", left: "10%" }, // Left Midfielder
      8: { top: "24%", left: "33%" }, // Center Left Midfielder
      4: { top: "24%", left: "57%" }, // Center Right Midfielder
      7: { top: "24%", left: "81%" }, // Right Midfielder
      10: { top: "33%", left: "45.5%" }, // Attacking Midfielder
      9: { top: "42%", left: "45.5%" }, // Forward
    },
    // 4141 Formation
    4141: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      4: { top: "24%", left: "45.5%" }, // Defensive Midfielder
      11: { top: "33%", left: "10%" }, // Left Midfielder
      10: { top: "33%", left: "33%" }, // Center Left Midfielder
      8: { top: "33%", left: "57%" }, // Center Right Midfielder
      7: { top: "33%", left: "81%" }, // Right Midfielder
      9: { top: "42%", left: "45.5%" }, // Forward
    },
    // 4231 Formation
    4231: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      4: { top: "24%", left: "35%" }, // Defensive Midfielder
      8: { top: "24%", left: "55%" }, // Defensive Midfielder
      11: { top: "33%", left: "15%" }, // Left Attacking Midfielder
      10: { top: "33%", left: "45.5%" }, // Center Attacking Midfielder
      7: { top: "33%", left: "75%" }, // Right Attacking Midfielder
      9: { top: "42%", left: "45.5%" }, // Forward
    },
    // 4321 Formation
    4321: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      7: { top: "24%", left: "20%" }, // Defensive Midfielder
      4: { top: "24%", left: "45.5%" }, // Defensive Midfielder
      8: { top: "24%", left: "70%" }, // Defensive Midfielder
      11: { top: "33%", left: "33%" }, // Left Attacking Midfielder
      10: { top: "33%", left: "57%" }, // Center Attacking Midfielder
      9: { top: "42%", left: "45.5%" }, // Right Attacking Midfielder
    },
    532: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "15%", left: "5%" }, // Left Defender
      4: { top: "15%", left: "25%" }, // Center Left Defender
      5: { top: "15%", left: "45.5%" }, // Center Defender
      6: { top: "15%", left: "65%" }, // Center Right Defender
      2: { top: "15%", left: "85%" }, // Right Defender
      11: { top: "27%", left: "15%" }, // Left Midfielder
      8: { top: "27%", left: "45.5%" }, // Center Midfielder
      7: { top: "27%", left: "75%" }, // Right Midfielder
      9: { top: "40%", left: "30%" }, // Forward Left
      10: { top: "40%", left: "60%" }, // Forward Right
    },
    541: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "15%", left: "5%" }, // Left Defender
      4: { top: "15%", left: "25%" }, // Center Left Defender
      5: { top: "15%", left: "45.5%" }, // Center Defender
      6: { top: "15%", left: "65%" }, // Center Right Defender
      2: { top: "15%", left: "85%" }, // Right Defender
      11: { top: "27%", left: "15%" }, // Left Midfielder
      10: { top: "27%", left: "35%" }, // Center Left Midfielder
      8: { top: "27%", left: "55%" }, // Center Right Midfielder
      7: { top: "27%", left: "75%" }, // Right Midfielder
      9: { top: "40%", left: "45.5%" }, // Forward
    },
    352: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "15%", left: "20%" }, // Left Defender
      5: { top: "15%", left: "45.5%" }, // Center Defender
      6: { top: "15%", left: "71%" }, // Right Defender
      3: { top: "31%", left: "5%" }, // Center Left Midfielder
      8: { top: "27%", left: "25%" }, // Left Midfielder
      11: { top: "27%", left: "45.5%" }, // Forward Right
      7: { top: "27%", left: "65%" }, // Center Right Midfielder
      2: { top: "31%", left: "85%" }, // Right Midfielder
      9: { top: "40%", left: "30%" }, // Attacking Midfielder
      10: { top: "40%", left: "60%" }, // Forward Left
    },
    343: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "15%", left: "20%" }, // Left Defender
      5: { top: "15%", left: "45.5%" }, // Center Defender
      6: { top: "15%", left: "71%" }, // Right Defender
      3: { top: "27%", left: "10%" },
      8: { top: "27%", left: "33%" }, // Center Midfielder
      7: { top: "27%", left: "57%" }, // Right Midfielder
      2: { top: "27%", left: "81%" },
      11: { top: "40%", left: "15%" }, // Left Midfielder
      9: { top: "40%", left: "45.5%" }, // Center Forward
      10: { top: "40%", left: "75%" }, // Right Forward
    },
    4222: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      8: { top: "24%", left: "30%" }, // Defensive Midfielder
      4: { top: "24%", left: "60%" }, // Defensive Midfielder
      11: { top: "33%", left: "30%" }, // Left Attacking Midfielder
      7: { top: "33%", left: "60%" }, // Right Attacking Midfielder
      9: { top: "42%", left: "30%" }, // Forward Left
      10: { top: "42%", left: "60%" }, // Forward Right
    },
    3511: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "14%", left: "20%" }, // Center Midfielder
      5: { top: "14%", left: "45.5%" }, // Center Right Defender
      6: { top: "14%", left: "71%" }, // Center Left Defender
      3: { top: "28%", left: "5%" }, // Left Defender
      8: { top: "24%", left: "25%" }, // Left Midfielder
      11: { top: "24%", left: "45.5%" }, // Center Left Midfielder
      7: { top: "24%", left: "65%" }, // Center Right Midfielder
      2: { top: "28%", left: "85%" }, // Right Defender
      10: { top: "33%", left: "45.5%" }, // Right Midfielder
      9: { top: "42%", left: "45.5%" }, // Forward
    },
    3421: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "14%", left: "20%" }, // Right Forward
      5: { top: "14%", left: "45.5%" },
      6: { top: "14%", left: "71%" }, // Center Defender
      3: { top: "24%", left: "10%" }, // Left Defender
      8: { top: "24%", left: "33%" }, // Center Midfielder
      7: { top: "24%", left: "57%" }, // Right Midfielder
      2: { top: "24%", left: "81%" }, // Right Defender
      11: { top: "34%", left: "30%" }, // Left Midfielder
      10: { top: "34%", left: "60%" }, // Left Forward
      9: { top: "42%", left: "45.5%" }, // Center Forward
    },
    3412: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "14%", left: "20%" }, // Left Defender
      5: { top: "14%", left: "45.5%" }, // Center Left Defender
      6: { top: "14%", left: "71%" }, // Center Right Defender
      3: { top: "24%", left: "10%" }, // Right Defender
      8: { top: "24%", left: "33%" }, // Left Midfielder
      7: { top: "24%", left: "57%" }, // Center Midfielder
      2: { top: "24%", left: "81%" }, // Right Midfielder
      9: { top: "34%", left: "45.5%" }, // Forward Left
      11: { top: "42%", left: "30%" }, // Forward Center
      10: { top: "42%", left: "60%" }, // Forward Right
    },
    3142: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      6: { top: "14%", left: "20%" }, // Defensive Midfielder
      4: { top: "14%", left: "45.5%" }, // Center Right Defender
      5: { top: "14%", left: "71%" }, // Center Left Defender
      8: { top: "23%", left: "45.5%" }, // Center Left Midfielder
      3: { top: "32%", left: "10%" }, // Left Defender
      11: { top: "32%", left: "33%" }, // Left Midfielder
      7: { top: "32%", left: "57%" }, // Center Right Midfielder
      2: { top: "32%", left: "81%" }, // Right Defender
      10: { top: "42%", left: "30%" }, // Right Midfielder
      9: { top: "42%", left: "60%" }, // Forward
    },
    "343d": {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "13%", left: "20%" }, // Left Forward
      5: { top: "13%", left: "45.5%" }, // Center Right Defender
      6: { top: "13%", left: "71%" }, // Center Defender
      8: { top: "22%", left: "45.5%" }, // Center Midfielder
      3: { top: "28%", left: "10%" }, // Left Defender
      2: { top: "28%", left: "81%" }, // Right Defender
      7: { top: "33%", left: "45.5%" }, // Left Midfielder
      11: { top: "42%", left: "15%" }, // Right Midfielder
      9: { top: "42%", left: "45.5%" }, // Center Forward
      10: { top: "42%", left: "75%" }, // Right Forward
    },
    4132: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      4: { top: "24%", left: "45.5%" }, // Defensive Midfielder
      11: { top: "33%", left: "15%" }, // Forward Right
      8: { top: "33%", left: "45.5%" }, // Left Midfielder
      7: { top: "33%", left: "75%" }, // Center Midfielder
      10: { top: "42%", left: "30%" }, // Right Midfielder
      9: { top: "42%", left: "60%" }, // Forward Left
    },
    4240: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      8: { top: "24%", left: "30%" }, // Left Midfielder
      4: { top: "24%", left: "60%" }, // Center Left Midfielder
      11: { top: "34%", left: "10%" }, // Center Right Midfielder
      10: { top: "34%", left: "33%" }, // Right Midfielder
      9: { top: "34%", left: "57%" }, // Forward Left
      7: { top: "34%", left: "81%" }, // Forward Right
    },
    4312: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      3: { top: "14%", left: "10%" }, // Left Defender
      6: { top: "14%", left: "33%" }, // Center Left Defender
      5: { top: "14%", left: "57%" }, // Center Right Defender
      2: { top: "14%", left: "81%" }, // Right Defender
      11: { top: "24%", left: "15%" }, // Defensive Midfielder
      4: { top: "24%", left: "45.5%" }, // Attacking Midfielder
      7: { top: "24%", left: "75%" }, // Left Forward
      8: { top: "33%", left: "45.5%" }, // Right Forward
      10: { top: "42%", left: "30%" }, // Striker
      9: { top: "42%", left: "60%" }, // Forward
    },
    3241: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "14%", left: "20%" }, // Left Defender
      5: { top: "14%", left: "45.5%" }, // Center Left Defender
      6: { top: "14%", left: "71%" }, // Center Right Defender
      3: { top: "24%", left: "30%" }, // Right Defender
      2: { top: "24%", left: "60%" }, // Defensive Midfielder
      11: { top: "33%", left: "10%" }, // Left Midfielder
      8: { top: "33%", left: "33%" }, // Center Midfielder
      7: { top: "33%", left: "57%" }, // Attacking Midfielder
      10: { top: "33%", left: "81%" }, // Striker
      9: { top: "42%", left: "45.5%" }, // Forward
    },
    3331: {
      1: { top: "1%", left: "45.5%" }, // Goalkeeper
      4: { top: "14%", left: "20%" }, // Left Defender
      5: { top: "14%", left: "45.5%" }, // Center Defender
      6: { top: "14%", left: "71%" }, // Center Defender
      3: { top: "24%", left: "20%" }, // Right Defender
      8: { top: "24%", left: "45.5%" }, // Left Midfielder
      2: { top: "24%", left: "71%" }, // Center Midfielder
      11: { top: "33%", left: "10%" }, // Right Midfielder
      7: { top: "33%", left: "45.5%" }, // Forward
      10: { top: "33%", left: "81%" }, // Forward
      9: { top: "42%", left: "45.5%" }, // Striker
    },
  };
  const getEventForPlayer = (playerId) => {
    if (!matchEvents.events) return [];
    const filteredEvents = matchEvents.events.filter(
      (event) => event.playerMainSourceId === playerId
    );

    // Check if there are any events for the player
    if (filteredEvents.length > 0) {
      // If event type 8 occurs, remove event type 2
      if (filteredEvents.some((event) => event.type === 8)) {
        return filteredEvents.filter((event) => event.type !== 2);
      } else {
        return filteredEvents;
      }
    } else {
      return [];
    }
  };

  const renderPlayers = (players, isHome) => {
    const formationKey = isHome ? homeFormation : guestFormation;
    const formationPositions = playersPositions[formationKey] || {
      top: "0%",
      left: "0%",
    };
    return players.map((player) => {
      const playerPosition = formationPositions[player.formationPosition] || {};
      const playerEvents = getEventForPlayer(player.playerId, isHome);

      return (
        <div
          key={player.playerId}
          className="absolute flex flex-col items-center"
          style={{
            top: isHome
              ? playerPosition.top
              : `${93 - parseFloat(playerPosition.top)}%`,
            left: isHome ? "" : playerPosition.left,
            right: isHome ? playerPosition.left : "",
          }}
        >
          {player.formationPosition === 1 ? (
            <img
              src="/img/svg/homeGoalKit.svg"
              alt="Goalkeeper"
              className="w-[35px] h-[30px]"
            />
          ) : isHome ? (
            <img
              src="/img/svg/kitHome.svg"
              alt="Player"
              className="w-[35px] h-[30px]"
            />
          ) : (
            <img
              src="/img/svg/kitAway.svg"
              alt="Player"
              className="w-[35px] h-[30px]"
            />
          )}
          {playerEvents.map((event, index) => (
            <EventIndicator key={event.id} playerEvent={event} index={index} />
          ))}
          <span className="font-light text-white text-xs absolute top-0 left-0 w-full h-full flex items-center justify-center">
            {player.shirtNumber}
          </span>
          <div className="font-light text-black text-xs absolute top-6 left-0 w-full h-full flex items-center justify-center font-semibold">
            {extractSurname(player.playerName).includes("-") ? (
              <>
                <span>
                  <br />
                  {extractSurname(player.playerName)}
                </span>
              </>
            ) : (
              <span>{extractSurname(player.playerName)}</span>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="relative h-full w-full bg-bgLight">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <img
            src="/img/svg/pitch.svg"
            alt="Pitch"
            className="w-full h-full px-1"
          />
          <div>
            <div className="absolute top-5 left-4 inline-block bg-gray-200 p-1 rounded">
              <p className="text-gray-400 font-medium">
                {formatFormation(homeFormation)}
              </p>
            </div>
            <div>{renderPlayers(homeTeamPlayers, true)}</div>
          </div>

          <div>
            <div className="absolute bottom-5 left-4 inline-block bg-gray-200 p-1 rounded">
              <p className="text-gray-400 font-medium">
                {formatFormation(guestFormation)}
              </p>
            </div>
            <div>{renderPlayers(guestTeamPlayers, false)}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default Lineups;
