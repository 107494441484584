import React from "react";

const EventTypeSubstitution = ({ minute, playerName, isHome, playerOut }) => {
  return (
    <div className="">
      {isHome === 1 ? (
        <React.Fragment>
          <div className="flex items-center justify-start h-10">
            <div className="flex items-center justify-start font-medium text-scoreText text-black mr-2">
              {minute}'
            </div>
            <div className="flex items-center mr-3 py-1">
              <React.Fragment>
                <img
                  src="/img/svg/substitution.svg"
                  alt="Substitution"
                  className="h-15 w-15 pl-1"
                />
              </React.Fragment>
            </div>
            {playerOut && (
              <div className="justify-start">
                <div className="font-medium text-scoreText text-black">
                  {playerName}
                </div>
                <div className="font-medium text-textSecondaryLight text-scoreText">
                  {playerOut}
                </div>
              </div>
            )}
            {!playerOut && (
              <div className="font-medium text-scoreText text-black">
                {playerName}
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="flex items-center justify-end h-10">
          <React.Fragment>
            {playerOut && (
              <div className="text-right">
                <div className="font-medium text-scoreText text-black">
                  {playerName}
                </div>
                <div className="font-medium text-textSecondaryLight text-scoreText">
                  {playerOut}
                </div>
              </div>
            )}
            {!playerOut && (
              <div className="font-medium text-scoreText text-black">
                {playerName}
              </div>
            )}
            <div className="flex items-center ml-3 py-1">
              <React.Fragment>
                <img
                  src="/img/svg/substitution.svg"
                  alt="Substitution"
                  className="h-15 w-15 pl-1"
                />
              </React.Fragment>
            </div>
            <div className="w-30 h-30 flex items-center justify-center font-medium text-scoreText text-black ml-2">
              {minute}'
            </div>
          </React.Fragment>
        </div>
      )}
    </div>
  );
};

export default EventTypeSubstitution;
