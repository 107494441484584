import React from "react";
import HeadingTitle from "../components/layout/HeadingTitle";
import { formatDate, useNavigation } from "../services/shared-service";

function TeamLastFiveH2HMatches({
  lastFiveH2HMatches,
  homeTeam,
  guestTeam,
  selectedTeam,
}) {
  const navigateTo = useNavigation();

  const getResultLabel = (match) => {
    if (selectedTeam === "homeTeam") {
      if (match.homeTeam.scored > match.guestTeam.scored) {
        return homeTeam.id === match.homeTeam.id ? "P" : "I";
      } else if (match.homeTeam.scored < match.guestTeam.scored) {
        return homeTeam.id === match.homeTeam.id ? "I" : "P";
      } else {
        return "N";
      }
    } else {
      if (match.guestTeam.scored > match.homeTeam.scored) {
        return guestTeam.id === match.guestTeam.id ? "P" : "I";
      } else if (match.guestTeam.scored < match.homeTeam.scored) {
        return guestTeam.id === match.guestTeam.id ? "I" : "P";
      } else {
        return "N";
      }
    }
  };

  return (
    <div>
      {lastFiveH2HMatches.length > 0 && (
        <div className="container mx-auto mb-8 sm:px-6 md:px-8">
          <HeadingTitle title={"H2H utakmice"} />
          <div>
            {lastFiveH2HMatches
              .slice()
              .reverse()
              .map((match, index) => (
                <div className="border-b border-divider" key={index}>
                  <div
                    className="cursor-pointer"
                    onClick={() => navigateTo(`/match/${match.id}`)}
                  >
                    <div className="flex items-center mt-1">
                      <div className="w-full flex justify-between pl-3">
                        <div className="flex items-center">
                          <img
                            src="/img/svg/goal.svg"
                            alt="Competition Logo"
                            className="w-4 h-4 mr-2"
                          />
                          <p className="text-[#212629] font-bold">
                            {match.league.name}
                          </p>
                        </div>
                        <p className="max-w-[400px] text-scoreText mt-1 pr-3 text-textSecondary">
                          {formatDate(match.date)}
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <table className="flex-grow">
                        <tbody>
                          <tr className="">
                            <td className="">
                              <div className="flex pl-6 my-2">
                                <img
                                  src={match.homeTeam.logoUrl}
                                  alt={match.homeTeam.name}
                                  className="object-cover rounded-full h-[24px] w-[24px]"
                                />
                                <h2 className="pl-4 text-black">
                                  {match.homeTeam.name}
                                </h2>
                              </div>
                            </td>
                            <td>
                              <div className="flex justify-end items-center">
                                <div
                                  className={`pr-3 mt-1 ${
                                    match.homeTeam.scored >
                                    match.guestTeam.scored
                                      ? "text-black font-medium"
                                      : "text-textSecondary"
                                  }`}
                                >
                                  {match.homeTeam.scored}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="">
                              <div className="flex pl-6 mb-2">
                                <img
                                  src={match.guestTeam.logoUrl}
                                  alt={match.guestTeam.name}
                                  className="object-cover rounded-full h-[24px] w-[24px]"
                                />
                                <h2 className="pl-4 text-black">
                                  {match.guestTeam.name}
                                </h2>
                              </div>
                            </td>
                            <td>
                              <div className="flex justify-end items-center">
                                <div
                                  className={`pr-3 mb-2 ${
                                    match.guestTeam.scored >
                                    match.homeTeam.scored
                                      ? "text-black font-medium"
                                      : "text-textSecondary"
                                  }`}
                                >
                                  {match.guestTeam.scored}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="border-l border-divider my-2"></div>
                      <div className="w-6 h-6 mx-3 items-center justify-center mt-7 text-white text-center">
                        <div
                          className={`rounded-lg ${
                            getResultLabel(match) === "P"
                              ? "bg-mainGreen"
                              : getResultLabel(match) === "N"
                              ? "bg-secondaryBgLight"
                              : "bg-redContainer"
                          }`}
                        >
                          {getResultLabel(match)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamLastFiveH2HMatches;
