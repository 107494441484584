import React, { useState } from "react";

const ExpandHeadingTitle = ({ title, onClick }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
    if (onClick) {
      onClick(); // Call the onClick function passed from parent
    }
  };

  return (
    <div
      style={{ background: "#E3E6E7" }}
      className="bg-bgDark py-3 px-2 font-medium cursor-pointer"
      onClick={toggleExpand}
    >
      <div className="flex justify-between items-center">
        <h4>{title}</h4>
        <img
          src={
            expanded ? "/img/svg/collapseIcon.svg" : "/img/svg/expandIcon.svg"
          }
          alt={expanded ? "Collapse" : "Expand"}
          className="w-4 h-4"
        />
      </div>
    </div>
  );
};

export default ExpandHeadingTitle;
