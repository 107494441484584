import { API_URL, HEADERS } from "../config/api";

export const fetchMatchFormations = async (matchId) => {
  try {
    const response = await fetch(`${API_URL}matches/${matchId}/formations`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match formations");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match formations:", error);
    return null;
  }
};

export const getInjuredSuspended = async (teamId) => {
  try {
    const response = await fetch(`${API_URL}injuries-suspensions?teamId=${teamId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch injured/suspended players", teamId);
      return null;
    }
  } catch (error) {
    console.error("Error fetching injured/suspended players:", error, teamId);
    return null;
  }
};

export const fetchMatchProvisionalLineUps = async (matchId) => {
  try {
    const response = await fetch(`${API_URL}matches/${matchId}/provisional-lineups`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match formations");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match formations:", error);
    return null;
  }
};
