import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchLeagueMatches,
  fetchLeagues,
  fetchLeagueScorers,
} from '../services/competition-service';
import { useParams } from 'react-router-dom';
import Loader from '../shared/Loader';
import InfoTab from '../components/competition/CompetitionInfoTab';
import CompetitionMatches from '../shared/CompetitionMatches';
import CompetitionPlayerStatistics from '../components/competition/CompetitionPlayerStatistics';
import { TeamStandingsTable } from '../shared/TeamStandingsTable';
import { Tabs } from '../shared/Tabs';

const Competition = () => {
  const [activeTab, setActiveTab] = useState('info');
  const [leagueDetails, setLeagueDetails] = useState(null);
  const [leagueMatches, setLeagueMatches] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leagueScorers, setLeagueScorers] = useState(null);
  const [seasonId, setSeasonId] = useState(0);
  const { shortname } = useParams();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  /**
   * ## init
   * @description Fetch leagues, find id by shortname and fetch matches and scores
   * @type {(function(): Promise<void>)|*}
   */
  const init = useCallback(async () => {
    if (!shortname) return;
    fetchLeagues()
      .then((data) => {
        return data
          .flatMap(({ leagues }) => leagues)
          .find((league) => league.shortname === shortname);
      })
      .then((league) => {
        if (league) {
          fetchMatches(league.id);
          fetchScorers(league.id);
          setLeagueDetails(league);
          setSeasonId(league.seasonId);
        }
      })
      .catch((err) => console.error('Error fetching leagues:', err))
      .finally(() => setLoading(false));
  }, [shortname]);

  useEffect(() => {
    (async () => await init())();
  }, [init]);

  const fetchMatches = async (leagueId) => {
    fetchLeagueMatches(leagueId)
      .then((data) => setLeagueMatches(data || []))
      .catch((err) => console.error('Error fetching matches:', err));
  };

  const fetchScorers = async (leagueId) => {
    fetchLeagueScorers(leagueId)
      .then((data) => setLeagueScorers(data))
      .catch((err) => console.error('Error fetching scorerss:', err));
  };

  const tabs = [
    {
      label: 'Info',
      isActive: activeTab === 'info',
      onClick: () => handleTabClick('info'),
    },
    {
      label: 'Tablica',
      isActive: activeTab === 'table',
      onClick: () => handleTabClick('table'),
    },
    {
      label: 'Utakmice',
      isActive: activeTab === 'games',
      onClick: () => handleTabClick('games'),
    },
    {
      label: 'Statistika',
      isActive: activeTab === 'statistics',
      onClick: () => handleTabClick('statistics'),
    },
  ];

  return (
    <div>
      <div className="p-3 bg-white ">
        <Tabs tabs={tabs} />
      </div>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {activeTab === 'info' && (
              <InfoTab
                matches={leagueMatches}
                shortname={shortname}
                seasonId={seasonId}
                leagueName={leagueDetails.name}
                totalMatches={leagueMatches}
              />
            )}
            {activeTab === 'table' && (
              <TeamStandingsTable
                leagueShortname={leagueDetails.shortname}
                leagueName={leagueDetails.subLeagueName}
                homeTeam=""
                guestTeam=""
              />
            )}

            {activeTab === 'games' && (
              <CompetitionMatches leagueMatches={leagueMatches} />
            )}
            {activeTab === 'statistics' && (
              <CompetitionPlayerStatistics leagueScorers={leagueScorers} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Competition;
