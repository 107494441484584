import { Link } from "react-router-dom";
import { TeamFlagBadge } from "../../shared/TeamFlagBadge";
const MatchInfo = ({
  matchId,
  homeTeam,
  guestTeam,
  homeLogoUrl,
  guestLogoUrl,
  time,
  date,
}) => {
  return (
    <div className="w-1/3 p-1 snap-start">
      <Link
        to={`/prematch/${matchId}`} // Navigate to prematch page
        className="text-black text-opacity-80 hover:text-opacity-100 focus:outline-none focus:text-opacity-100"
      >
        <div className="shadow-md bg-cardContainerGrey rounded-lg h-35 flex flex-col justify-between p-3">
          <div className="text-xs text-textSecondaryLight flex justify-center items-center space-x-1">
            <p className="truncate w-20 text-center">{homeTeam}</p>
            <span> - </span>
            <p className="truncate w-20 text-center">{guestTeam}</p>
          </div>
          <div className="flex items-center justify-center my-3">
            <TeamFlagBadge flagUrl={homeLogoUrl} />
            <span className="text-bgMain font-fw700 px-4">:</span>
            <TeamFlagBadge flagUrl={guestLogoUrl} />
          </div>
          <div className="text-center font-fw700 text-text16">{time}</div>
          <div className="text-center text-textSecondaryLight text-lineupsText pb-2">
            {date}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MatchInfo;
