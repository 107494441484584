import React from "react";
import PropTypes from "prop-types";

const WebHeadingTitle = ({ message }) => {
  return (
    <div className="w-full h-10 bg-[#AF2418] flex justify-center items-center text-white text-lg font-bold my-3">
      <span className="text-[14px] font-roboto font-bold leading-4 tracking-[0.15px] text-center text-white">
        {message}
      </span>
    </div>
  );
};

WebHeadingTitle.propTypes = {
  message: PropTypes.string.isRequired,
};

export default WebHeadingTitle;
