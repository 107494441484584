import React from "react";

function StandingsOverall({ standingsData }) {
  return (
    <table className="min-w-max w-full table-auto">
      <thead>
        <tr className="text-sm text-slate-500">
          <th className="py-3 px-1 text-center">#</th>
          <th className="py-3 px-2 text-left">Klub</th>
          <th className="py-3 px-2 text-center">O</th>
          <th className="py-3 px-2 text-center">P</th>
          <th className="py-3 px-2 text-center">N</th>
          <th className="py-3 px-2 text-center">I</th>
          <th className="py-3 px-2 text-center hidden md:table-cell">+ / -</th>
          <th className="py-3 px-2 text-center">GR</th>
          <th className="py-3 px-2 text-center">Bod</th>
        </tr>
      </thead>
      <tbody className="text-gray-600 text-sm font-light">
        {standingsData.map((team, index) => (
          <tr key={team["teamId"]} className="border-b border-gray-100">
            <td className="py-3 px-1 text-center font-medium">{index + 1}.</td>
            <td className="py-3 px-2 flex items-center font-medium">
              <img src={team["teamLogo"]} alt={team["teamName"]} className="mr-2 w-4 h-4" /> {/* Add the team logo here */}
              {team["teamName"]}
            </td>
            <td className="py-3 px-2 text-center font-medium">{team["matchesOverall"]}</td>
            <td className="py-3 px-2 text-center font-medium">{team["homeWins"] + team["awayWins"]}</td>
            <td className="py-3 px-2 text-center font-medium">{team["homeDraws"] + team["awayDraws"]}</td>
            <td className="py-3 px-2 text-center font-medium">{team["homeLosses"] + team["awayLosses"]}</td>
            <td className="py-3 px-2 text-center font-medium hidden md:table-cell">
              {team["homeScored"] + team["awayScored"]}:{team["homeReceived"] + team["awayReceived"]}
            </td>
            <td className="py-3 px-2 text-center font-medium">{team["homeScored"] + team["awayScored"] - (team["homeReceived"] + team["awayReceived"])}</td>
            <td className="py-3 px-2 text-center font-bold">{team["pointsOverall"]}</td>
          </tr>
        ))}
        <tr>
          <td colSpan="9" className="py-3 px-2 text-left font-light">
            Podatke omogućio HRnogomet
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default StandingsOverall;
