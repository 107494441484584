import { useEffect, useState, useRef, useCallback } from "react";
import TabButton from "../shared/TabButtons";
import PlayerProfile from "../components/player/PlayerProfile";
import PlayersStatistics from "../components/player/PlayerStatistics";
import { getPlayer } from "../services/player-service";
import { getTeamPlayers } from "../services/team-service";
import { useParams } from "react-router-dom";
import Loader from "../shared/Loader";
import { getCountries } from "../services/shared-service";

const Player = () => {
  const [playerDetails, setPlayerDetails] = useState(null);
  const [teamList, setTeamList] = useState(null);
  const [countries, setCountries] = useState([]);
  const [activeTab, setActiveTab] = useState("profile");
  const { playerId } = useParams();
  const initialFetchRef = useRef(true);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchPlayerDetails = async () => {
      const data = await getPlayer(playerId);
      setPlayerDetails(data);
      if (data && data.team_id) {
        fetchTeamList(data.team_id);
      }
    };

    const fetchTeamList = async (teamId) => {
      const teamData = await getTeamPlayers(teamId);
      setTeamList(teamData);
    };

    if (playerId) {
      fetchPlayerDetails();
    }
  }, [playerId]);

  const fetchCountriesAndMapImgUrl = useCallback(async () => {
    try {
      if (initialFetchRef.current) {
        const data = await getCountries();
        setCountries(data.countries);
        initialFetchRef.current = false;
      }
  
      if (playerDetails && countries.length > 0) {
        // Check if imagePath needs updating
        const updatedPlayerDetails = {
          ...playerDetails,
          imagePath: countries.find(country => country.name === playerDetails.imageUrl)?.image_path || playerDetails.imagePath
        };
  
        // Only update if imagePath has changed
        if (updatedPlayerDetails.imagePath !== playerDetails.imagePath) {
          setPlayerDetails(updatedPlayerDetails);
        }
      }
    } catch (error) {
      console.error("Error fetching countries or mapping image URL:", error);
    }
  }, [playerDetails, countries]);
  

  useEffect(() => {
    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    };
  
    if (playerDetails && !isValidUrl(playerDetails.imageUrl)) {
      fetchCountriesAndMapImgUrl();
    }
  }, [playerDetails, fetchCountriesAndMapImgUrl]);

  return (
    <div>
      <div className="p-3 bg-white">
        <div style={{ background: "#F4F7F8" }} className="flex mb-1 rounded-sm overflow-hidden pt-1 pb-1">
          <TabButton label="Profil" isActive={activeTab === "profile"} onClick={() => handleTabClick("profile")} />
          <TabButton label="Statistike" isActive={activeTab === "statistics"} onClick={() => handleTabClick("statistics")} />
        </div>
      </div>
      {playerDetails === null || teamList === null ? (
        <Loader />
      ) : (
        <div>
          {activeTab === "profile" && <PlayerProfile playerDetails={playerDetails} teamList={teamList} />}
          {activeTab === "statistics" && <PlayersStatistics />}
        </div>
      )}
    </div>
  );
};

export default Player;
