import { API_URL, HEADERS } from "../config/api";

export const fetchLeagues = async () => {
  try {
    const response = await fetch(`${API_URL}county/leagues`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match details");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match details:", error);
    return null;
  }
};

export const fetchLeagueMatches = async (leagueId) => {
  try {
    const response = await fetch(`${API_URL}matches?leagueId=${leagueId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match details");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match details:", error);
    return null;
  }
};

export const fetchLeagueScorers = async (leagueId) => {
  try {
    const response = await fetch(`${API_URL}scorers?leagueId=${leagueId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch league scorers");
      return null;
    }
  } catch (error) {
    console.error("Error fetching league scorers:", error);
    return null;
  }
};

export const fetchTopBettingMarkets = async (shortname) => {
  try {
    const response = await fetch(`${API_URL}leagues/top-betting-markets?shortname=${shortname}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch top betting markets");
      return null;
    }
  } catch (error) {
    console.error("Error fetching top betting markets:", error);
    return null;
  }
};

export const fetchSeasonStats = async (seasonId) => {
  try {
    const response = await fetch(`${API_URL}team-season-stats?seasonid=${seasonId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch season Stats");
      return null;
    }
  } catch (error) {
    console.error("Error fetching season stats:", error);
    return null;
  }
};
