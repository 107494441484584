// import React, { useState } from "react";
import HeadingTitle from "../components/layout/HeadingTitle";
import { useNavigation } from "../services/shared-service";
import { TeamFlagBadge } from "./TeamFlagBadge";
function TeamMatches({ teamMatches, selectedTeamId }) {
  const today = new Date();
  const navigateTo = useNavigation();

  // Filter past and future matches
  const pastMatches = teamMatches.matches.filter((match) => {
    return new Date(match.date) <= today && match.played === 1;
  });

  const futureMatches = teamMatches.matches.filter((match) => {
    return new Date(match.date) >= today || match.played === 0;
  });

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("hr-HR", { day: "numeric", month: "long" });
  };

  const getResultLabel = (match) => {
    // Ensure all IDs are numbers for comparison !!!!!!!!!!!!
    const homeTeamId = Number(match.homeTeam.id);
    const guestTeamId = Number(match.guestTeam.id);
    const selectedId = Number(selectedTeamId);

    if (selectedId === homeTeamId) {
      if (match.homeTeam.scored > match.guestTeam.scored) {
        return "P"; // Home team wins
      } else if (match.homeTeam.scored < match.guestTeam.scored) {
        return "I"; // Home team loses
      } else {
        return "N"; // Draw
      }
    } else if (selectedId === guestTeamId) {
      if (match.guestTeam.scored > match.homeTeam.scored) {
        return "P"; // Guest team wins
      } else if (match.guestTeam.scored < match.homeTeam.scored) {
        return "I"; // Guest team loses
      } else {
        return "N"; // Draw
      }
    } else {
      return "N"; // If the selected team is neither the home nor guest team
    }
  };

  return (
    <div className="container mx-auto sm:px-6 md:px-8">
      <div className="flex justify-center">
        {/* Your buttons for filtering view can go here if needed */}
      </div>
      {futureMatches.length > 0 && (
        <div>
          <HeadingTitle title={"Nadolazeće utakmice"} />
          {futureMatches.length > 0 ? (
            futureMatches.map((match, index) => (
              <div
                key={index}
                className={`bg-lightGrey cursor-pointer`}
                onClick={() => navigateTo(`/prematch/${match.id}`)}
              >
                <div className="flex items-center">
                  <div className="w-full flex justify-between pl-3">
                    <div className="flex items-center">
                      <img
                        src="/img/svg/goal.svg"
                        alt="Competition Logo"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-[#212629] font-fw700">
                        {match.league.name}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="max-w-[400px] text-scoreText mt-1  pr-2 text-textSecondary">
                        {match.time}
                      </p>
                      <p className="max-w-[400px] text-scoreText mt-1 pr-2 text-textSecondary">
                        {formatDate(match.date)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <table className="flex-grow">
                    <tbody>
                      <tr>
                        <td className="flex pl-6 my-2">
                          <TeamFlagBadge flagUrl={match.homeTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.homeTeam.name}
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex pl-6 mb-2">
                          <TeamFlagBadge flagUrl={match.guestTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.guestTeam.name}
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="border-l border-divider my-2"></div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-text14 font-fw500 p-3">
              <p>Trenutno nema nadolazećih utakmica</p>
            </div>
          )}
        </div>
      )}

      {pastMatches.length > 0 && (
        <div>
          <HeadingTitle title={"Odigrane utakmice"} />
          {pastMatches.length > 0 ? (
            pastMatches.map((match, index) => (
              <div
                key={index}
                className={`bg-containerPast cursor-pointer`}
                onClick={() => navigateTo(`/match/${match.id}`)}
              >
                <div className="flex items-center">
                  <div className="w-full flex justify-between pl-3">
                    <div className="flex items-center">
                      <img
                        src="/img/svg/goal.svg"
                        alt="Competition Logo"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-[#212629] font-fw700">
                        {match.league.name}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="max-w-[400px] text-scoreText mt-1  pr-2 text-textSecondary">
                        {match.time}
                      </p>
                      <p className="max-w-[400px] text-scoreText mt-1 pr-2 text-textSecondary">
                        {formatDate(match.date)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <table className="flex-grow">
                    <tbody>
                      <tr>
                        <td className="flex pl-6 my-2">
                          <TeamFlagBadge flagUrl={match.homeTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.homeTeam.name}
                          </h2>
                        </td>
                        <td>
                          <div className="flex justify-end items-center pr-3 mt-1">
                            {match.homeTeam.scored}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex pl-6 mb-2">
                          <TeamFlagBadge flagUrl={match.guestTeam.logoUrl} />
                          <h2 className="pl-4 text-black">
                            {match.guestTeam.name}
                          </h2>
                        </td>
                        <td>
                          <div className="flex justify-end items-center pr-3 mb-2">
                            {match.guestTeam.scored}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="border-l border-divider my-2"></div>
                  <div className="w-6 h-6 mx-3 items-center justify-center mt-7 text-white text-center">
                    <div
                      className={`rounded-lg ${
                        getResultLabel(match) === "P"
                          ? "bg-mainGreen"
                          : getResultLabel(match) === "N"
                            ? "bg-secondaryBgLight"
                            : "bg-redContainer"
                      }`}
                    >
                      {getResultLabel(match)}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-center">
              <p className="text-scoreText font-fw500">
                Trenutno nema odigranih utakmica
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TeamMatches;
