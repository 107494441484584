import TeamGameCard from "./TeamGameCard";
import TeamStatistic from "../components/team/TeamStatistics";
import TeamDetailsLastFiveMatches from "./TeamDetailsLastFiveMatches";
import { TeamStandingsTable } from "./TeamStandingsTable";
import { EmptyState } from "./EmptyState";

const TeamDetails = ({
  teamDetails = null,
  matchDetails = null,
  lastFiveMatches,
  selectedTeamId,
}) => {
  const team =
    teamDetails && Object.keys(teamDetails).length > 0 ? teamDetails : null;

  const match =
    matchDetails && matchDetails.matches && matchDetails.matches.length > 0
      ? matchDetails.matches[0]
      : null;

  if (!team) {
    return <EmptyState infoText="Trenutno nema detalja o timu" />;
  }

  return (
    <div>
      {team && <TeamGameCard team={team} />}
      {/* <BetButtons></BetButtons> */}
      {match && (
        <TeamStandingsTable
          leagueShortname={match.league.shortname}
          leagueName={match.league.name}
          homeTeam={match.homeTeam}
          guestTeam={match.guestTeam}
        />
      )}
      <TeamDetailsLastFiveMatches
        lastFiveMatches={lastFiveMatches}
        teamId={selectedTeamId}
      />
      {match && <TeamStatistic matchDetails={match} />}
    </div>
  );
};

export default TeamDetails;
