import React, { useState, useEffect, useCallback } from "react";
import { TeamStandingsTable } from "../../shared/TeamStandingsTable";
import TeamLastFiveMatches from "../../shared/TeamLastFiveMatches";
import TeamLastFiveH2HMatches from "../../shared/TeamLastFiveH2HMatches";
import { fetchLeagues } from "../../services/competition-service";

export default function MatchH2H({
  matchDetails,
  homeTeamLastFive,
  guestTeamLastFive,
  lastFiveH2HMatches,
  matchId,
}) {
  const match = matchDetails.matches[0];
  const leagueShortname = match.league.shortname;

  const [selectedTeam, setSelectedTeam] = useState("homeTeam");
  const [leagueDetails, setLeagueDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const fetchLeague = useCallback(async () => {
    try {
      const data = await fetchLeagues();
      let foundLeague = null;

      data.forEach((country) => {
        country.leagues.forEach((league) => {
          if (league.shortname === leagueShortname) {
            foundLeague = league;
          }
        });
      });

      if (foundLeague) {
        setLeagueDetails(foundLeague);
      }
      setIsLoading(false); // Set loading to false after fetching league details
    } catch (err) {
      console.error("Error fetching leagues:", err);
      setIsLoading(false); // Ensure loading state is updated even on error
    }
  }, [leagueShortname]);

  useEffect(() => {
    fetchLeague();
  }, [fetchLeague]);

  if (!matchDetails.matches || matchDetails.matches.length === 0 || isLoading) {
    return null; // Return null if matches are not available or still loading
  }

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  return (
    <div className="container mx-auto sm:px-6 md:px-8 overflow-auto">
      {leagueDetails && leagueDetails.isCup !== 1 && (
        <TeamStandingsTable
          leagueShortname={match.league.shortname}
          leagueName={match.league.name}
          homeTeam={match.homeTeam}
          guestTeam={match.guestTeam}
          matchId={match.id}
        />
      )}
      <TeamLastFiveMatches
        homeTeam={match.homeTeam}
        guestTeam={match.guestTeam}
        matchId={matchId}
        homeTeamLastFive={homeTeamLastFive}
        guestTeamLastFive={guestTeamLastFive}
        selectedTeam={selectedTeam}
        onTeamSelect={handleTeamSelect}
      />
      <TeamLastFiveH2HMatches
        lastFiveH2HMatches={lastFiveH2HMatches}
        matchId={matchId}
        homeTeam={match.homeTeam}
        guestTeam={match.guestTeam}
        selectedTeam={selectedTeam}
        onTeamSelect={handleTeamSelect}
      />
    </div>
  );
}
