import { API_URL, HEADERS } from "../config/api";
import { formatStatValue } from "./shared-service";

export const fetchStandingsData = async (leagueShortname) => {
  if (leagueShortname) {
    try {
      const response = await fetch(
        `${API_URL}team-standings?shortname=${leagueShortname}`,
        { headers: HEADERS }
      );
      const data = await response.json();

      if (data.subLeagues && data.subLeagues.length > 0) {
        // Return subleagues with their respective team standings
        return data.subLeagues.map((subLeague) => ({
          subLeagueName: subLeague.subLeagueName,
          hasSubLeague: true,
          teams: subLeague.teamStandings.sort((a, b) => {
            if (a.pointsOverall !== b.pointsOverall) {
              return b.pointsOverall - a.pointsOverall;
            }
            return (
              b.homeScored +
              b.awayScored -
              b.homeReceived -
              b.awayReceived -
              (a.homeScored + a.awayScored - a.homeReceived - a.awayReceived)
            );
          }),
        }));
      } else if (data.teamStandings) {
        // Wrap the team standings in a default subleague
        return [
          {
            // subLeagueName: "Overall",
            teams: data.teamStandings.sort((a, b) => {
              if (a.pointsOverall !== b.pointsOverall) {
                return b.pointsOverall - a.pointsOverall;
              }
              return (
                b.homeScored +
                b.awayScored -
                b.homeReceived -
                b.awayReceived -
                (a.homeScored + a.awayScored - a.homeReceived - a.awayReceived)
              );
            }),
          },
        ];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};

export const getLeagueSeasonTotalStats = async (seasonId) => {
  try {
    const response = await fetch(
      `${API_URL}team-season-stats?seasonid=${seasonId}`,
      {
        headers: HEADERS,
      }
    );
    if (response.ok) {
      const data = await response.json();
      const formattedData = {
        scoredGoals: formatStatValue(data.scoredGoals),
        expectedGoals: formatStatValue(data.expectedGoals),
        concededGoals: formatStatValue(data.concededGoals),
        expectedConcededGoals: formatStatValue(data.expectedConcededGoals),
        totalShoots: formatStatValue(data.totalShoots),
        shootsOnTarget: formatStatValue(data.shootsOnTarget),
        cornerKicks: formatStatValue(data.cornerKicks),
        yellowCards: formatStatValue(data.yellowCards),
        redCards: formatStatValue(data.redCards),
      };
      return formattedData;
    } else {
      console.error("Failed to fetch season stats:", seasonId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching season stats", seasonId, error);
    return [];
  }
};
