import React, { useState, useEffect } from "react";
import { API_URL, HEADERS, IFRAME_URL } from "../config/api";

export default function Landing() {
  const [counties, setCounties] = useState([]);
  const [roundNumbers, setRoundNumbers] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedLeagueParam, setSelectedLeagueParam] = useState("");
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedOption, setSelectedOption] = useState("standings");
  const [iframeWidth, setIframeWidth] = useState("100%"); // Default width
  const [iframeHeight, setIframeHeight] = useState("500"); // Default height
  const [round, setRound] = useState("");
  const [activeTab, setActiveTab] = useState("code");
  // eslint-disable-next-line
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    // Fetch data from API
    fetch(`${API_URL}county/leagues`, { headers: HEADERS })
      .then((response) => response.json())
      .then((data) => setCounties(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCountyChange = (e) => {
    setRoundNumbers([]);
    setSelectedOption("standings");
    setSelectedCounty(+e.target.value);
    setSelectedLeague(""); // Reset selected league when county changes
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleRoundChange = (e) => {
    setRound(e.target.value);
  };

  const getIframeUrl = () => {
    let url = `${IFRAME_URL}${selectedOption}?league=${selectedLeagueParam}`;
    if (selectedOption === "results" && round) {
      url += `&round=${round}`;
    }

    return url;
  };

  const generateIframeCode = () => {
    let url = getIframeUrl();
    const iframeCode = `<iframe src="${url}" frameborder="0" width="${iframeWidth}" height="${iframeHeight}"></iframe>`;
    return iframeCode;
  };

  const copyToClipboard = () => {
    const iframeCode = generateIframeCode();
    navigator.clipboard.writeText(iframeCode);
    alert("Kod kopiran u međuspremnik!");
  };

  const handleWidthChange = (e) => {
    setIframeWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setIframeHeight(e.target.value);
  };

  // eslint-disable-next-line
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const fetchMatchesData = async (leagueId) => {
    try {
      const matchesResponse = await fetch(`${API_URL}matches?leagueId=${leagueId}`, { headers: HEADERS });
      const matchesData = await matchesResponse.json();

      return matchesData;
    } catch (error) {
      console.error("Error fetching matches data:", error);
      return null;
    }
  };

  const handleLeagueChange = async (e) => {
    setRoundNumbers([]);
    setSelectedOption("standings");

    const leagueId = e.target.value;
    const leagueShortname = e.target.options[e.target.selectedIndex].getAttribute("shortname");
    setSelectedLeagueParam(leagueShortname);
    setSelectedLeague(leagueId);

    // Fetch matches data for the selected league
    const matchesData = await fetchMatchesData(leagueId);

    if (matchesData && matchesData.matches) {
      const roundsArray = processMatchesData(matchesData.matches);
      setRoundNumbers(roundsArray);
    }
  };

  const processMatchesData = (matchesData) => {
    const roundsSet = new Set();

    matchesData.forEach((match) => {
      const roundNumber = match.round;
      if (roundNumber) {
        roundsSet.add(roundNumber);
      }
    });

    const roundsArray = Array.from(roundsSet).sort((a, b) => a - b);
    return roundsArray;
  };

  return (
    <div className="container mx-auto p-4 ">
      <div style={{ background: "#AF2418", textAlign: "left" }} className="flex items-center mb-6 rounded">
        <h1 className="text-sm text-white mb-2 px-2 py-2 mt-2">U par jednostavnih koraka generirajte widget za vašu web stranicu.</h1>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div className="bg-gray-50 p-4 rounded-lg">
          <label htmlFor="county" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Odaberi županiju / natjecanje:
          </label>
          <select id="county" value={selectedCounty} onChange={handleCountyChange} className="w-full p-2 border border-gray-300 rounded-lg focus:ring-[#AF2418] focus:border-[#AF2418] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#AF2418] dark:focus:border-[#AF2418]">
            <option value="">Odaberi županiju / natjecanje</option>
            {counties.map((county) => (
              <option key={county.id} value={county.id}>
                {county.name}
              </option>
            ))}
          </select>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          {selectedCounty && (
            <div>
              <label htmlFor="league" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Odaberi ligu:
              </label>
              <select id="league" value={selectedLeague} onChange={handleLeagueChange} className="w-full p-2 border border-gray-300 rounded-lg focus:ring-[#AF2418] focus:border-[#AF2418] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#AF2418] dark:focus:border-[#AF2418]">
                <option value="">Odaberi ligu</option>
                {counties
                  .find((county) => county.id === selectedCounty)
                  ?.leagues.map((league) => (
                    <option key={league.id} value={league.id} shortname={league.shortname}>
                      {league.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          {selectedLeague && (
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Postavke widgeta:</label>
              <label className="inline-block mr-4 text-sm uppercase">
                <input type="radio" value="standings" checked={selectedOption === "standings"} onChange={handleOptionChange} className="mr-2" />
                Tablica
              </label>
              <label className="inline-block text-sm uppercase">
                <input type="radio" value="results" checked={selectedOption === "results"} onChange={handleOptionChange} className="mr-2" />
                Rezultati
              </label>
            </div>
          )}

          {selectedOption === "results" && selectedLeague && roundNumbers.length > 0 && (
            <div>
              <label className="block mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white">Kolo:</label>
              <select value={round} onChange={handleRoundChange} className="w-full p-2 border border-gray-300 rounded-lg text-sm">
                {roundNumbers.map((roundNumber) => (
                  <option key={roundNumber} value={roundNumber}>
                    Kolo {roundNumber}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedLeague && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-900 dark:text-white">Širina:</label>
                <input type="text" value={iframeWidth} onChange={handleWidthChange} className="w-2/3 p-2 border border-gray-300 rounded-lg text-sm" />
              </div>
            </div>
          )}

          {selectedLeague && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-900 dark:text-white">Visina:</label>
                <input type="text" value={iframeHeight} onChange={handleHeightChange} className="w-2/3 p-2 border border-gray-300 rounded-lg text-sm" />
              </div>
            </div>
          )}
        </div>

        {selectedLeague && (
          <div className="bg-gray-50 p-4 rounded-lg col-span-full">
            <div className="flex">
              <div className={`flex-1 p-2 cursor-pointer ${activeTab === "code" ? "bg-gray-200" : ""} text-center border rounded-tl-lg border-gray-300`} onClick={() => setActiveTab("code")}>
                Kod
              </div>
              <div className={`flex-1 p-2 cursor-pointer ${activeTab === "preview" ? "bg-gray-200" : ""} text-center border rounded-tr-lg border-gray-300`} onClick={() => setActiveTab("preview")}>
                Pregled
              </div>
            </div>

            {activeTab === "code" && (
              <div className="mt-4">
                <code className="block w-full bg-gray-100 p-4 rounded">{generateIframeCode()}</code>
                <button onClick={copyToClipboard} className="bg-[#AF2418] text-white py-1 px-2 rounded text-sm mt-2">
                  Kopiraj kod
                </button>
              </div>
            )}

            {activeTab === "preview" && (
              <div className="mt-4">
                <iframe title="landing page iframe" src={getIframeUrl()} width={iframeWidth} height={iframeHeight}></iframe>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
