import React from "react";

export const TeamFlagBadge = ({ flagUrl, teamName, onClick }) => {
  if (!flagUrl && !teamName) return null;
  return (
    <div className="flex flex-col items-center">
      {flagUrl && (
        <div
          className="cursor-pointer rounded-full h-[24px] w-[24px] grid place-content-center overflow-hidden border-2 border-gray-300"
          onClick={onClick}
        >
          <img
            src={flagUrl}
            alt="team badge"
            className="object-cover rounded-full h-[24px] w-[24px]"
          />
        </div>
      )}
      {teamName && (
        <p className="font-medium text-black text-lineupsText mt-1">
          {teamName}
        </p>
      )}
    </div>
  );
};
