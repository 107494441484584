import React, { useState } from "react";
import HeadingTitle from "../components/layout/HeadingTitle";
import { formatDate, useNavigation } from "../services/shared-service";

function TeamLastFiveMatches({
  homeTeam,
  guestTeam,
  homeTeamLastFive,
  guestTeamLastFive,
  onTeamSelect,
}) {
  const [selectedTeam, setSelectedTeam] = useState("homeTeam");
  const navigateTo = useNavigation();
  const handleTeamClick = (team) => {
    setSelectedTeam(team);
    onTeamSelect(team);
  };

  const matches =
    selectedTeam === "homeTeam" ? homeTeamLastFive : guestTeamLastFive;

  return (
    <div>
      {(homeTeamLastFive.length > 0 || guestTeamLastFive.length > 0) && (
        <div className="container mx-auto sm:px-6 md:px-8">
          <HeadingTitle title={"Posljednjih 5 utakmica po timu"} />

          <div className="flex justify-center">
            <div
              className={`w-1/2 mr-4 flex items-center justify-center cursor-pointer py-3 ${
                selectedTeam === "homeTeam"
                  ? "bgMain border-b-2 border-bgMain"
                  : ""
              }`}
              onClick={() => handleTeamClick("homeTeam")}
            >
              {homeTeam && (
                <img
                  src={homeTeam.logoUrl}
                  alt={homeTeam.name}
                  className="object-cover rounded-full h-[24px] w-[24px]"
                />
              )}{" "}
              <span
                className={`uppercase text-center text-scoreText pl-2 ${
                  selectedTeam === "homeTeam"
                    ? "text-bgMain"
                    : "text-textSecondary"
                }`}
              >
                {homeTeam.name}
              </span>
            </div>
            <div
              className={`w-1/2 flex items-center justify-center cursor-pointer py-3 ${
                selectedTeam === "guestTeam" ? "border-b-2 border-bgMain" : ""
              }`}
              onClick={() => handleTeamClick("guestTeam")}
            >
              {guestTeam && (
                <img
                  src={guestTeam.logoUrl}
                  alt={guestTeam.name}
                  className="object-cover rounded-full h-[24px] w-[24px]"
                />
              )}
              <span
                className={`uppercase text-center text-scoreText pl-2 ${
                  selectedTeam === "guestTeam"
                    ? "text-bgMain"
                    : "text-textSecondary"
                }`}
              >
                {guestTeam.name}
              </span>
            </div>
          </div>
          <div>
            {matches
              .slice()
              .reverse()
              .map((match, index) => (
                <div className="border-b border-divider" key={index}>
                  <div
                    className="cursor-pointer"
                    onClick={() => navigateTo(`/match/${match.id}`)}
                  >
                    <div className="flex items-center mt-1">
                      <div className="w-full flex justify-between pl-3">
                        <div className="flex items-center">
                          <img
                            src="/img/svg/goal.svg"
                            alt="Competition Logo"
                            className="w-4 h-4 mr-2"
                          />
                          <p className="text-[#212629] font-fw700">
                            {match.league.name}
                          </p>
                        </div>
                        <p className="max-w-[400px] text-scoreText mt-1 pr-3 text-textSecondary">
                          {formatDate(match.date)}
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <table className="flex-grow">
                        <tbody>
                          <tr className="">
                            <td className="">
                              <div className="flex pl-6 my-2">
                                {homeTeam && (
                                  <img
                                    src={match.homeTeam.logoUrl}
                                    alt={match.homeTeam.name}
                                    className="object-cover rounded-full h-[24px] w-[24px]"
                                  />
                                )}
                                <h2 className="pl-4 text-black">
                                  {match.homeTeam.name}
                                </h2>
                              </div>
                            </td>
                            <td>
                              <div className="flex justify-end items-center">
                                <div
                                  className={`pr-3 mt-1 ${
                                    match.homeTeam.scored >
                                    match.guestTeam.scored
                                      ? "text-black font-medium"
                                      : "text-textSecondary"
                                  }`}
                                >
                                  {match.homeTeam.scored}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="">
                              <div className="flex pl-6 mb-2">
                                {guestTeam && (
                                  <img
                                    src={match.guestTeam.logoUrl}
                                    alt={match.guestTeam.name}
                                    className="object-cover rounded-full h-[24px] w-[24px]"
                                  />
                                )}
                                <h2 className="pl-4 text-black">
                                  {match.guestTeam.name}
                                </h2>
                              </div>
                            </td>
                            <td>
                              <div className="flex justify-end items-center">
                                <div
                                  className={`pr-3 mb-2 ${
                                    match.guestTeam.scored >
                                    match.homeTeam.scored
                                      ? "text-black font-medium"
                                      : "text-textSecondary"
                                  }`}
                                >
                                  {match.guestTeam.scored}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="border-l border-divider my-2"></div>
                      <div className="w-6 h-6 mx-3 items-center justify-center mt-7 text-white text-center">
                        {selectedTeam === "homeTeam" ? (
                          match.homeTeam.id === homeTeam.id ? (
                            match.homeTeam.scored > match.guestTeam.scored ? (
                              <div className="bg-mainGreen rounded-lg">P</div>
                            ) : match.homeTeam.scored ===
                              match.guestTeam.scored ? (
                              <div className="bg-secondaryBgLight rounded-lg">
                                N
                              </div> // Render "N" for tie matches
                            ) : (
                              <div className="bg-redContainer rounded-lg">
                                I
                              </div>
                            )
                          ) : match.guestTeam.scored > match.homeTeam.scored ? (
                            <div className="bg-mainGreen rounded-lg">P</div>
                          ) : match.homeTeam.scored ===
                            match.guestTeam.scored ? (
                            <div className="bg-secondaryBgLight rounded-lg">
                              N
                            </div> // Render "N" for tie matches
                          ) : (
                            <div className="bg-redContainer rounded-lg">I</div>
                          )
                        ) : match.homeTeam.id === guestTeam.id ? (
                          match.homeTeam.scored > match.guestTeam.scored ? (
                            <div className="bg-mainGreen rounded-lg">P</div>
                          ) : match.homeTeam.scored ===
                            match.guestTeam.scored ? (
                            <div className="bg-secondaryBgLight rounded-lg">
                              N
                            </div> // Render "N" for tie matches
                          ) : (
                            <div className="bg-redContainer rounded-lg">I</div>
                          )
                        ) : match.guestTeam.scored > match.homeTeam.scored ? (
                          <div className="bg-mainGreen rounded-lg">P</div>
                        ) : match.homeTeam.scored === match.guestTeam.scored ? (
                          <div className="bg-secondaryBgLight rounded-lg">
                            N
                          </div> // Render "N" for tie matches
                        ) : (
                          <div className="bg-redContainer rounded-lg">I</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamLastFiveMatches;
