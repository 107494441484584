import { API_URL, HEADERS } from "../config/api";

export const fetchMatchDetails = async (matchId) => {
  try {
    const response = await fetch(`${API_URL}matches/${matchId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match details");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match details:", error);
    return null;
  }
};

export const getTeamMatches = async (teamId) => {
  try {
    const response = await fetch(`${API_URL}matches/teams?teamid=${teamId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch last five matches for team:", teamId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching last five matches for team:", teamId, error);
    return [];
  }
};

export const getTeamLastFive = async (teamId) => {
  try {
    const response = await getTeamMatches(teamId);
    // Filter matches where played = 1
    const filteredMatches = response.matches.filter((match) => match.played === 1);
    // Take last 5 matches
    const lastFiveMatches = filteredMatches.slice(-5);
    return lastFiveMatches;
  } catch (error) {
    console.error("Error getting last five matches for team:", teamId, error);
    return [];
  }
};

export const getLastFiveH2HMatches = async (homeTeamId, guestTeamId) => {
  try {
    const response = await fetch(`${API_URL}matches/teams?teamid=${homeTeamId},${guestTeamId}`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      // Filter matches where played = 1 and homeTeamId or guestTeamId matches
      const filteredMatches = data.matches.filter((match) => match.played === 1 && ((match.homeTeam.id === homeTeamId && match.guestTeam.id === guestTeamId) || (match.homeTeam.id === guestTeamId && match.guestTeam.id === homeTeamId)));
      // Take last 5 matches
      const lastFiveMatches = filteredMatches.slice(-5);
      return lastFiveMatches;
    } else {
      console.error("Failed to fetch last five H2H matches");
      return [];
    }
  } catch (error) {
    console.error("Error fetching last five H2H matches:", error);
    return [];
  }
};

export const fetchMatchStats = async (matchId) => {
  try {
    const response = await fetch(`${API_URL}matches/${matchId}/stats`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match stats");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match stats:", error);
    return null;
  }
};

export const fetchMatchEvents = async (matchId) => {
  try {
    const response = await fetch(`${API_URL}matches/${matchId}/events`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch match events");
      return null;
    }
  } catch (error) {
    console.error("Error fetching match events:", error);
    return null;
  }
};

export const getMatchFacts = async (matchId) => {
  try {
    const response = await fetch(`${API_URL}matches/${matchId}/betting-facts`, {
      headers: HEADERS,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch betting facts for match:", matchId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching betting facts for match:", matchId, error);
    return [];
  }
};
