import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <body className="h-screen overflow-hidden flex items-center justify-center">
        <div className="bg-gray-100 h-screen justify-center w-screen">
          <center className="mt-24 m-auto">
            <div className=" tracking-widest mt-4">
              <span className="text-gray-500 text-6xl block">
                <span>4 0 4</span>
              </span>
              <span className="text-gray-500 text-xl">Sorry, We couldn't find what you are looking for!</span>
            </div>
          </center>
        </div>
      </body>
    </>
  );
}
