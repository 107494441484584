import React from 'react';
import HeadingTitle from '../layout/HeadingTitle';
import NoDataMessage from '../../shared/NoDataMessage';

const CompetitionPlayerStatistics = ({ leagueScorers }) => {
  // Check if leagueScorers is null or undefined
  if (!leagueScorers) {
    return (
      <NoDataMessage message="Trenutno nema informacija o strijelcima i asistentima." />
    );
  }

  const typeTitles = {
    bestShooters: 'Najbolji strijelci',
    bestAssists: 'Najbolji asistenti',
  };

  let bestShooters = leagueScorers.filter((player) => player.goals > 0);
  let bestAssists = leagueScorers.filter((player) => player.assists > 0);

  // Sort the bestShooters array by goals in descending order and take the top 5
  bestShooters = bestShooters.sort((a, b) => b.goals - a.goals).slice(0, 5);
  bestAssists = bestAssists.sort((a, b) => b.assists - a.assists).slice(0, 5);

  const playerList = [
    ...bestShooters.map((player) => ({ ...player, type: 'bestShooters' })),
    ...bestAssists.map((player) => ({ ...player, type: 'bestAssists' })),
  ];

  // Group players by type
  const groupedPlayers = playerList.reduce((acc, player) => {
    if (!acc[player.type]) {
      acc[player.type] = [];
    }
    acc[player.type].push(player);
    return acc;
  }, {});

  return (
    <div>
      {Object.entries(groupedPlayers).map(([type, players], index) => (
        <div key={index}>
          <div className="py-1">
            <HeadingTitle title={typeTitles[type]} />
          </div>
          {players.map((player, idx) => (
            <React.Fragment key={idx}>
              {idx !== 0 && <hr className="bg-divider border-1" />}
              <div className="flex items-center pl-2 relative p-1">
                <div className="px-2 pr-3">{idx + 1}</div>
                <div className="p-1">
                  <img
                    src={player.imgUrl || '/img/svg/playerIcon.svg'}
                    alt={player.name}
                    className="object-cover rounded-full h-[26px] w-[26px]"
                  />
                </div>
                <div className="pr-1 flex-grow flex flex-col">
                  <p className="text-lineupsText font-fw500">{player.name}</p>
                  <div className="flex items-center mt-1">
                    <img
                      src={player.teamLogo || '/img/svg/countryIcon.svg'}
                      alt={player.country}
                      className="object-cover rounded-full h-[14px] w-[12px] mr-1"
                    />
                    <div className="text-xs text-textSecondaryLight font-fw500">
                      {player.teamName}
                    </div>
                  </div>
                </div>
                <div className="mr-3">
                  <p className="font-fw700 text-scoreText">
                    {type === 'bestShooters' ? player.goals : player.assists}
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CompetitionPlayerStatistics;
